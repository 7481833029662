import React from "react";

// import { IconCheckCircle } from "../../icons/IconCheckCircle";
// import { IconInfo1 } from "../../icons/IconInfo1";
// import { IconButtonSmall } from "../../icons/IconButtonSmall";

import "./style.less";
import { IconWarning } from "../../icons/IconWarning";

export const AlertLight = ({
  text = "Do not receive the email? Check your spam filter or try another email address below.",
  classes = "",
}) => {
  return (
    <div className={`alert-light warning alert-light-instance ${classes}`}>
      {/* {["error", "info"].includes(property1) && (
        <IconInfo1 className="instance-node" color={property1 === "info" ? "#63A6F7" : "#BA1A1A"} />
      )} */}

      <IconWarning className="instance-node" color="#F99146" />

      <div className={`this-is-an-error w-100--36`}>
        {/* {property1 === "error" && <p className="text-wrapper-7">This is an error alert — check it out!</p>}

        {property1 === "info" && <p className="text-wrapper-7">This is an info alert — check it out!</p>} */}

        <p className="text-wrapper-7">{text}</p>
      </div>
      {/* <IconButtonSmall
        configuration="standard"
        icon={<IconClose4 className="instance-node" color="#6B6D82" />}
        stateProp="enabled"
        tooltipClassName="icon-button-small-instance"
        tooltipText="Discard"
      /> */}

      {/* {property1 === "success" && (
        <>
          <IconCheckCircle className="instance-node" />
          <p className={`text-wrapper ${divClassName}`}>This is a success alert — check it out!</p>
          <>
            {showButton && (
              <IconButtonSmall
                configuration="standard"
                icon={<IconClose4 className="instance-node" color="#6B6D82" />}
                stateProp="enabled"
                tooltipClassName="icon-button-small-instance"
                tooltipText="Discard"
              />
            )}
          </>
        </>
      )} */}
    </div>
  );
};
