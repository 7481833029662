/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconTrash = ({ color = "black", className }) => {
  return (
    <svg
      className={`icon-trash ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M12.5 2.5V3.33333H16.6666V5H15.8333V15.8333C15.8333 16.75 15.0833 17.5 14.1666 17.5H5.83331C4.91665 17.5 4.16665 16.75 4.16665 15.8333V5H3.33331V3.33333H7.49998V2.5H12.5ZM5.83331 15.8333H14.1666V5H5.83331V15.8333ZM7.49998 6.66667H9.16665V14.1667H7.49998V6.66667ZM12.5 6.66667H10.8333V14.1667H12.5V6.66667Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

IconTrash.propTypes = {
  color: PropTypes.string,
};
