import React from "react";

import "./style.less";

import { HeaderApplicants } from "../../components/HeaderApplicants";
import { ApplicantsTab } from "../../components/ApplicantsTab/ApplicantsTab";

export const ApplicantScreen = () => {
  return (
    <div className="p-3 screen-body">
      <div className="h-100">
        <HeaderApplicants
          name={"Applicants"}
          info={" Applicants name"}
        />
        <ApplicantsTab />
      </div>
    </div>
  );
};
