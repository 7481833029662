import { authenticationContants, allConstants } from '../_constants';
import { mainService } from '../_requests';

export const userActions = {
    registration,
    registrationFinish,
    login,
    logout,
    resetPassword,
};

function registration(first_Name, last_Name, phone_number, email, legal_name, phisical_adress, mc_number, usdot_number) {
    const req = {
        action: 'registration_company',
        registration_type: 'registration_company_start',
        first_name: first_Name,
        last_name: last_Name,
        phone_number: phone_number,
        work_e_mail: email,
        legal_name: legal_name,
        phisical_adress: phisical_adress,
        mc_number: +mc_number,
        USDOT_Number: +usdot_number,
    }
    return async dispatch => {
        dispatch({
            type: authenticationContants.USER_REGISTRATION_REQUEST,
            payload: { req }
        });
        return mainService.mainRequest('registration-company', req).then( (data) => {
            if (data.status === 'error') {
                const errorConstant = authenticationContants.USER_REGISTRATION_FAILURE;
                dispatch({
                    type: errorConstant,
                    payload: data.error
                });
            } else {
                dispatch({
                    type: authenticationContants.USER_REGISTRATION_SUCCESS,
                    payload: {...req, ...data}
                });
            }
            return {...req, ...data};
        }).catch((err) => {
            const errorConstant = authenticationContants.USER_REGISTRATION_FAILURE;
            dispatch({
                type: errorConstant,
                payload: err.error
            });
            return {...req, ...err};
        });
    };
}

function registrationFinish(params, pwd) {
    const req = {
        registration_type: 'registration_company_finish',
        params,
        pwd,
    }
    return async dispatch => {
        dispatch({
            type: authenticationContants.USER_REGISTRATION_REQUEST,
            payload: { req }
        });
        return mainService.mainRequest('registration-company', req).then( (data) => {
            if (data.status === 'error') {
                const errorConstant = authenticationContants.USER_REGISTRATION_FAILURE;
                dispatch({
                    type: errorConstant,
                    payload: data.note ? {...data.error, note: data.note} : data.error
                });
            } else {
                dispatch({
                    type: authenticationContants.USER_REGISTRATION_SUCCESS,
                    payload: {...req, ...data}
                });
            }
            return {...req, ...data};
        }).catch((err) => {
            const errorConstant = authenticationContants.USER_REGISTRATION_FAILURE;
            dispatch({
                type: errorConstant,
                payload: err.error
            });
            return {...req, ...err};
        });
    };
}

function resetPassword(req = {}) {
    return async dispatch => {
        dispatch({
            type: allConstants.ACTION_REQUEST,
            payload: { req }
        });
        return mainService.mainRequest('reset-password', req).then( (data) => {
            if (data.status === 'error') {
                const errorConstant = allConstants.ACTION_FAILURE;
                dispatch({
                    type: errorConstant,
                    payload: data.error
                });
            } else {
                dispatch({
                    type: allConstants.ACTION_SUCCESS,
                    payload: {...req, ...data}
                });
            }
            return {...req, ...data};
        }).catch((err) => {
            const errorConstant = allConstants.ACTION_FAILURE;
            dispatch({
                type: errorConstant,
                payload: err.error
            });
            return {...req, ...err};
        });
    };
}

function login(public_key, password) {
    const req = { login_type: 'login_company', public_key, pwd: password };
    return async dispatch => {
        dispatch({
            type: authenticationContants.USER_LOGIN_REQUEST,
            payload: { req }
        });
        return mainService.mainRequest('login', req).then( (data) => {
            if (data.status === 'error') {
                const errorConstant = authenticationContants.USER_LOGIN_FAILURE;
                dispatch({
                    type: errorConstant,
                    payload: data.error
                });
            } else {
                dispatch(success({...data}, authenticationContants.USER_LOGIN_SUCCESS));
            }
            return {...req, ...data};
        }).catch((err) => {
            const errorConstant = authenticationContants.USER_LOGIN_FAILURE;
            dispatch({
                type: errorConstant,
                payload: err.error
            });
            return {...req, ...err};
        });
        
    };
}
function success(payload, dispatch_type) {
    const userData = {
        'token': payload.token,
        'reg_id': payload.account.id
    };
    mainService.setToken(payload.token);
    const userDataResult = JSON.stringify(Object.assign({}, userData));
    sessionStorage.setItem('userData', userDataResult);
    return {
        type: dispatch_type,
        payload
    };
}

function logout() {
    sessionStorage.removeItem('userData');
    return { type: authenticationContants.USER_LOGOUT };
}
