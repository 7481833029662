
import PropTypes from "prop-types";
import React from "react";

export const IconNavigateBefore = ({ color = "black", className = '' }) => {
  return (
    <svg
      className={`icon-navigate-before ${className}`}
      fill="none"
      height="19"
      viewBox="0 0 18 19"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M12.5282 5.32312L11.4707 4.26562L6.2207 9.51562L11.4707 14.7656L12.5282 13.7081L8.3432 9.51562L12.5282 5.32312Z"
        fill={color}
      />
    </svg>
  );
};

IconNavigateBefore.propTypes = {
  color: PropTypes.string,
};
