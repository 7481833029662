
import React, { useState, useMemo } from "react";

import { RegGetStarted } from "./RegGetStarted";
import { RegAccountInformation } from "./RegAccountInformation/RegAccountInformation";
import { RegVerify } from "./RegVerify/RegVerify";
import { RegPassword } from "./RegPassword/RegPassword";

import "./style.less";

type Props = {
  page?: number;
}

const Registration = ({page = 0}: Props) => {

  const [pageNumber, setPageNumber] = useState(page);

  const content_element = useMemo(() => {
    let content: any = <RegGetStarted
      next={() => setPageNumber(1)}
    />;
    switch (pageNumber) {
      case 1:
        content = <RegAccountInformation
          next={() => setPageNumber(2)}
        />
        break;
      case 2:
        content = <RegVerify
          previous={() => setPageNumber(1)}
        />
        break;
      case 3:
        content = <RegPassword/>
        break;
      default: 0;
    }
    return content;
  }, [pageNumber])

  return (
    <div className="authContainer">
      <div className="registration p-3">
        <div className="left-side">
          <div className="logo-container">
            <a href={window.location.origin}>
              <img className="layer" alt="Layer" src="/img/Logo_№1_2x.png" />
            </a>
          </div>
          <div className="help-title">Need Help?</div>
          <div className="help-contact">help@oculus-freight.com</div>
        </div>
        <div className="right-side">
          {content_element}
        </div>
      </div>
    </div>
  );
};

export { Registration };