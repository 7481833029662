
import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Button } from "@mui/base";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"; 
import CheckIcon from "@mui/icons-material/Check";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { StatusCompanyWorker } from "../StatusCompanyWorker";
import { Dispatch, STORE, CompanyWorker } from "../../_redux/types";
import { allActions } from '../../_redux/_actions';
import { allConstants } from '../../_redux/_constants';
import {
  MyIconButton,
} from "../../screens/VendorsScreen/ButtonsVendors";

import "./style.less";

type Props = {
  worker: CompanyWorker
}

export const HeaderCompanyWorker = ({ worker }: Props) => {
  const navigate = useNavigate();

  const dispatch: Dispatch = useDispatch();
  const {system_statuses} = useSelector((state: STORE) => state.appData.dictionaries);
  const { company_id } = useSelector( (state: STORE) => state.appData.company_information );

  const workerDeactivate: CompanyWorker | {} = useMemo(() => {
    return worker.system_status == system_statuses.find( (status) => status.code == 'manager_operations_deactivate')?.dict_id;
  }, [worker, system_statuses]);

  const changeActiveted = async () => {
    if(workerDeactivate) {
      const res: any = await allActions.companyWorkerActivate(dispatch, worker.company_worker_id);
      if (res.type === allConstants.COMPANY_WORKER_ACTIVATE) {
        allActions.getCompanyWorkers(dispatch, company_id);
      }
    } else {
      const res: any = await allActions.companyWorkerDeactivate(dispatch, worker.company_worker_id);
      if (res.type === allConstants.COMPANY_WORKER_DEACTIVATE) {
        allActions.getCompanyWorkers(dispatch, company_id);
      }
    }
  };

  return (
    <>
      <div className="head">
        <div className="header-title">
          <div className="header-div">
            <div className="screen-header-title-container ">
              <MyIconButton onClick={() => navigate(-1)}/>
              <div className="screen-header-title ">
                <span className="header-vendors"> User /</span> {worker.first_name + ' ' + worker.last_name}
              </div>
              <StatusCompanyWorker status={workerDeactivate ? 'inactive' : 'activate' } />
            </div>
            <div className="header-buttons">
              <Button
                className="header-button-applicants px-3"
                // variant="outlined"
                onClick={changeActiveted}
              >
                {workerDeactivate ? 'Activate' : 'Deactivate'}
              </Button>
              {/* <Button
                className="header-button-applicants px-3"
                onClick={() => {}}
              >
                Delete
              </Button> */}
            </div>
          </div>
        </div>
        <div className="header-tags">
          <p className="text-gray-12">
            Phone: <span className={"text-primary-small"}>{worker.phone_number}</span>
          </p>
          <p className="text-gray-12">
            Email: <span className={"text-primary-small"}>{worker.e_mail}</span>
          </p>
        </div>
      </div>
    </>
  );
};
