import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { DropDownUser } from "../DropDownUser/DropDownUser";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { userActions } from "../../_redux/_actions";
import { STORE } from "../../_redux/types";

import "./style.less";
export const DrawerUser = ({ onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { reg_id, account, company_information } = useSelector(
    (state: STORE) => state.appData
  );

  const fullname = useMemo(() => {
    let fullName = account.first_name + " " + account.last_name;
    if (reg_id == company_information.company_id)
      fullName =
        company_information.First_Name.value +
        " " +
        company_information.Last_Name.value;
    return fullName;
  }, [account]);

  return (
    <div className="User-drawer">
      <div className="user-drawer-wrap">
        <div className="wrap-modal-h">
          <div className="wrap-name">
            <button type="button" className="button-close" onClick={onClose}>
              <svg style={{ stroke: "#6B6D82" }} width="24" height="24">
                <use href={sprite + "#icon-x-close"} />
              </svg>
            </button>
            <div className="user-title">My Profile</div>
          </div>
          <div className="mt-3 position-relative">
            <div className="img-background-profile">
              <img src={"/img/Profile.png"} alt="Profile" />
            </div>
            <div className="img-avatar-wrapper">
              <div className="img-overlay-wrapper">
                <img
                  src={"/img/Avatar_Change.png"}
                  alt="Avatar"
                  className="img-avatar"
                />
                {/* <DropDownUser /> */}
              </div>
            </div>
          </div>
          <div className="user-title-wrap">
            <div className="user-title">{fullname}</div>
            <div className="user-subtitle">
              {reg_id == company_information.company_id
                ? "Owner"
                : "Manager operations"}
            </div>
          </div>
          <div className="user-info user-info-wrap">
            <div className="user-info-item">Business unit:</div>
            <div className="user-info-content">
              <p className="user-info-title mb-0">
                {company_information.Legal_Name.value}
              </p>
              <p className="user-info-title">
                {company_information.MC_Number.value}
              </p>
            </div>
          </div>
          <div className="user-info">
            <div className="user-info-item">Email (Login):</div>
            <div className="user-info-content">
              <span className="user-info-title">
                {company_information.Work_E_mail.value}
              </span>
            </div>
          </div>
          <div className="phone-info user-info">
            <div className="user-info-item">Phone:</div>
            <TextField
              id="outlined-basic"
              placeholder="Enter new phone"
              variant="outlined"
              defaultValue="+1(000) 000-0000"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-input": {
                  padding: "8px",
                  color: "var(--M3-sys-light-on-background, #1B1B1F)",
                  fontFamily: "Roboto",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  letterSpacing: "0.25px",
                  width: "100%",
                },
              }}
              disabled={true}
              value={company_information.Phone_Number.value}
            />
          </div>
        </div>

        <div className="user-modal-footer">
          {/* потім будемо дороблювати функціонал */}
          {/* <Button variant="text" className="user-button-delete">
            <svg width="18" height="18">
              <use style={{ fill: "inherit" }} href={sprite + "#icon-cancel"} />
            </svg>
            Delete account
          </Button> */}

          <div className="flex-fill"></div>

          <Button
            variant="contained"
            className="user-modal-button"
            onClick={() => {
              dispatch(userActions.logout());
              navigate("/login");
            }}
          >
            <svg width="18" height="18">
              <use style={{ fill: "#FFFFFF" }} href={sprite + "#icon-logout"} />
            </svg>
            Log out
          </Button>
        </div>
      </div>
    </div>
  );
};
