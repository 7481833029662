
import axios from 'axios';
import { SERVER_URL } from './index.js';
import { IObj } from '../../_redux/types';

export const mainService = {
    mainRequest,
    mainRequestGet,
    setToken
};

// задається функцією setToken, використовується в
let token;

const ERROR_VALUE = 'error';
const WARNING_VALUE = 'warning';

function setToken(tkn) {
    if (!tkn || typeof tkn !== 'string') {
        throw new Error('invalid token: ' + tkn);
    }
    token = tkn;
}

async function mainRequest(requestPath, requestBody, cancelToken) {
    const fullRequestBody = {
        ...requestBody,
        token: token || (getItemFromSessionStorage() || {token: null}).token,
    };
    if (!fullRequestBody.token) delete fullRequestBody.token;

    return axios.post(`${SERVER_URL}/${requestPath}`, fullRequestBody, cancelToken)
        .then(res => res.data).catch( (error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                const { response } = error;
                const errorMessage = response ?
                    typeof response.data === 'string' ?
                        response.status + ' ' + response.statusText :
                        response.data.error
                    : error;
                const isWarning = response && response.data.status === 'warning';
                const errorInfo: ErrorInfo = {
                    status: isWarning ? WARNING_VALUE : ERROR_VALUE,
                    error: { status: isWarning ? WARNING_VALUE : ERROR_VALUE, errorText: errorMessage }
                };

                if (response?.data?.note) errorInfo.note = response.data.note;

                return errorInfo;
            }
        });
}

async function mainRequestGet(requestPath, config = {}) {
    return axios.get(`${SERVER_URL}/${requestPath}`, config)
        .then(res => res).catch( (error) => {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                const { response } = error;
                const errorMessage = response ?
                    typeof response.data === 'string' ?
                        response.status + ' ' + response.statusText :
                        response.data.error
                    : error;
                const isWarning = response && response.data.status === 'warning';
                const errorInfo: ErrorInfo = {
                    status: isWarning ? WARNING_VALUE : ERROR_VALUE,
                    error: { status: isWarning ? WARNING_VALUE : ERROR_VALUE, errorText: errorMessage }
                };

                if (isWarning && response) errorInfo.note = response.data.note;

                return errorInfo;
            }
        });
}

export type ErrorInfo = {
    status: string,
    error: IObj,
    note?: string,
}

export type UserData = {
    reg_id: string,
    token: string | null,
}

const defaultsUserData: UserData = {
    reg_id: '',
    token: null,
};

export const isObject = (value: any) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) return true;
    return false;
};

const isValidUserData = (value: any, data: any) => {
    if (typeof data[value] === 'string') return true;
    return false;
};

export const getItemFromSessionStorage = (identifierUser?: any) => {
    const data = sessionStorage.getItem('userData');
    if (!data) return null;
    try {
        const parsedData = JSON.parse(data);

        if (!isObject(parsedData)) return null;

        if (identifierUser) {
            return isValidUserData(identifierUser, parsedData)
                ? parsedData[identifierUser]
                : defaultsUserData[identifierUser];
        }

        return parsedData;
    } catch (error) {
        console.log(error.message);
        return identifierUser ? defaultsUserData[identifierUser] : null;
    }
};