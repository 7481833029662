import React, {  } from "react";
import Button from '@mui/material/Button';

import "./style.less";

type Props = {
	handleClick: () => void,
	submitText?: string,
	startIcon?: any,
}

const BtnText = (props: Props) => {
	const { handleClick, submitText, startIcon = null } = props;

  return (
    <Button
      onClick={() => {handleClick()}}
      variant="outlined"
      className={`btn-text`}
      style={{textTransform: 'none'}}
      startIcon={startIcon}
    >{submitText}</Button>
  );
};

export { BtnText };
