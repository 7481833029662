
import React from "react";

import { IconArrowUpward } from "../../icons/IconArrowUpward";
import "./style.less";

export const TableHeadItem = ({ sort, className, text, primary = false, errrorColor = false }) => {

  return (
    <div
      className={`table-head-item ${className}`}
      onClick={() => {
      }}
    >
      <div className="text-main-12-16-dark">{text}</div>
      <div className={`sort`}>
        {sort && <IconArrowUpward
          color={primary ? "#C8C5CA" : errrorColor ? "#6B6D82" : ""}
        />}
      </div>
    </div>
  );
};
