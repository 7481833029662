import React from "react";

import "./style.less";

export const ShortInfoItem = ({
  title = "Qualification Checklist",
  subtitle = "_____",
}) => {
  return (
    <div className="ShortInfoItem">
      <div className="short-item-wrap">
        <div className="item-title">{title}</div>
        <div className="item-subtitle"> {subtitle}</div>
      </div>
    </div>
  );
};
