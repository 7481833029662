
import React from "react";

import "./style.less";

type Props = {
  status: "activate" | "inactive",
}

export const StatusCompanyWorker = ({ status = 'activate' }: Props) => {
  const statusStyles = status === 'activate' ? {
    backgroundColor: "#E5F3E0",
    border: "1px solid #346929",
    color: "#346929",
  } : {
    backgroundColor: "#FDE2E2",
    border: "1px solid #97171F",
    color: "#97171F",
  }
  return (
    <div
      style={{
        fontSize: "11px",
        lineHeight: "16px",
        borderRadius: "4px",
        fontWeight: "700",
        outline: "none",
        padding: "4px 8px",
        ...statusStyles
      }}
    >
      {status.toUpperCase()}
    </div>
  );
};
