
import { allConstants, authenticationContants, tr } from '../_constants';
import { validations, validate } from '../validations';

export const defDataValueStr = {
	value: '',
	isCorrect: false,
	helperText: '',
}
function genDefDataValueStr(value = '', isCorrect = false, helperText = '') {
	return {
		value,
		isCorrect,
		helperText,
	}
}

const defDataValueNumber = {
	value: null,
	isCorrect: false,
	helperText: '',
}
function genDefDataValueNumber(value) {
	return {
		value: value != null ? value : null,
		isCorrect: false,
		helperText: '',
	}
}

const defDictionaries = {
    countries: [],
    states: [],
    driver_license_classes: [],
    endorsements: [],
    vehicle_types: [],
    vehicle_types: [],
    company_roles: [],
    system_statuses: [],
    application_statuses: [],
}
export const defAlertModal = {
	openModalA: false,
	status: 200,
	statusText: '',
	statusData: {},
}
const initialState = {
	loginPageType: 'initial',
	urlData: {
		path: '',
		pageOfScreen: 0,
		params: {
		}
	},
	modalData: {
		open: false,
	},
	authData: {
		email: genDefDataValueStr('', true),
		pass: genDefDataValueStr('', true),
		resetEmail: genDefDataValueStr('', true),
		reg_create_pass: genDefDataValueStr('', true),
	},
	reg_id: '',
	token: '',
	account: {
		// company_id: 7
		// company_worker_id: 9
		// id: 9
		first_name: '',
		last_name: '',
		// reg_time: "2024-06-06T23:30:18.568Z"
		// last_visit: "2024-06-06T23:30:18.568Z"
		phone_number: '',
		public_key: '',
		role: null,
	},
	company_information: {
		company_id: '',
		First_Name: defDataValueStr,
		Last_Name: defDataValueStr,
		Phone_Number: defDataValueStr,
		Work_E_mail: defDataValueStr,
		Legal_Name: defDataValueStr,
		Phisical_Adress: defDataValueStr,
		MC_Number: defDataValueStr,
		USDOT_Number: defDataValueStr,
	},
	openUserdata: false,
	dictionaries: defDictionaries,
	applications: [],
	companyWorkers: [],
	uploadData: {},
	alertModal: defAlertModal,
};

export function appData(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case allConstants.SET_URL: {
			return {
				...state,
				urlData: {
					path: payload.path,
					currentPage: 0,
					params: {...payload.pathParams}
				}
			};
			// dispatch({
			//   type: allConstants.SET_URL,
			//   payload: { path: '', params: {} },
			// });
		}
		case allConstants.SET_CURRENT_PAGE: {
			return {
				...state,
				urlData: {
					...state.urlData,
					pageOfScreen: payload,
				}
			};
		}
		case allConstants.SET_KEY: {
			const {value, key1, key2} = payload;

			let key1_value = {};
			if(key2 != null) {
				key1_value = {
					...state[key1],
					[key2]: value
				}
			} else {
				key1_value = value
			}
			return {
				...state,
				[key1]: key1_value
			};
		}
		case allConstants.SET_KEY_VALUE: {
			const {value, key1, key2} = payload;
			
			let key1_value = {};
			let errorText = null;
			let newCorrect = false;
			if(key2 != null) {
				errorText = validate(value, validations[key1+'.'+key2]);
				newCorrect = !errorText;
				key1_value = {
					...state[key1],
					[key2]: {
						value: value,
						isCorrect: newCorrect,
						helperText: errorText,
					}
				}
			} else {
				errorText = validate(value, validations[key1]);
				newCorrect = !errorText;
				key1_value = {
					value: value,
					isCorrect: newCorrect,
					helperText: errorText,
				};
			}
			return {
				...state,
				[key1]: key1_value
			};
		}
		case allConstants.SET_KEY_INDEX_VALUE: {
			const {value, key1, key2, index} = payload;
			
			const errorText = validate(value, validations[key1+'.'+key2]);
			const newCorrect = !errorText;
			const key1_array = [
				...state[key1],
			];
			key1_array[index] = {
				...state[key1][index],
				[key2]: {
					value: value,
					isCorrect: newCorrect,
					helperText: errorText,
				}
			};
			return {
				...state,
				[key1]: key1_array
			};
		};
		case allConstants.SET_MODAL_DATA: {
			return {
				...state,
				modalData: {
					open: payload.open
				}
			};
		}
		case allConstants.LOAD_USER_DATA_SUCCESS:
		case authenticationContants.USER_LOGIN_SUCCESS: {
			return {
				...state,
				reg_id: payload.account.id,
				token: payload.token || (state.token ? state.token : ''),
				account: {
					first_name: payload.account.first_name,
					last_name: payload.account.last_name,
					phone_number: payload.account.phone_number,
					public_key: payload.account.public_key,
					role: payload.account.role,
				},
				company_information: {
					company_id: payload.company?.company_id,
					First_Name: genDefDataValueStr(payload.company?.first_name),
					Last_Name: genDefDataValueStr(payload.company?.last_name),
					Phone_Number: genDefDataValueStr(payload.company?.phone_number),
					Work_E_mail: genDefDataValueStr(payload.company?.work_e_mail),
					Legal_Name: genDefDataValueStr(payload.company?.legal_name),
					Phisical_Adress: genDefDataValueStr(payload.company?.phisical_adress),
					MC_Number: genDefDataValueStr(payload.company?.mc_number),
					USDOT_Number: genDefDataValueStr(payload.company?.USDOT_Number),
				},
			};
		}
		case authenticationContants.USER_LOGIN_FAILURE: {
			let key = '';
			let helperText = '';
			if(payload?.errorText === 'USER_NOT_FOUND') {
				key = 'email';
				helperText = tr(payload?.errorText);
			} else if(payload?.errorText === 'WRONG_PWD') {
				key = 'pass';
				helperText = tr(payload?.errorText);
			}
			return {
				...state,
				authData: {
					...state.authData,
					[key]: {
						value: state.authData[key]?.value,
						isCorrect: !helperText,
						helperText,
					},
				},
			};
		}
		case authenticationContants.USER_REGISTRATION_FAILURE: {
			let key = '';
			let helperText = '';
			if(payload?.errorText === 'OUTDATED_TOKEN') {
				key = 'reg_create_pass';
				helperText = tr(payload?.errorText);
			} else if(payload?.errorText === 'FIELD_MUST_BE_UNIQUE') {
				key = 'reg_create_pass';
				const frontFeald = payload.note === 'mc_number' ? 'MC Number' : 'Work E-mail';
				helperText = tr(payload?.errorText).replace("Field", 'Field ' + frontFeald);
			}
			return {
				...state,
				authData: {
					...state.authData,
					[key]: {
						value: state.authData[key]?.value,
						isCorrect: !helperText,
						helperText,
					},
				},
			};
		}
		case authenticationContants.USER_LOGOUT: {
			return initialState;
		}
		case allConstants.LOAD_DICTIONARIES_SUCCESS: {
			const dictionaries = {
				countries: [],
				states: [],
				driver_license_classes: [],
				endorsements: [],
				vehicle_types: [],
				company_roles: [],
				system_statuses: [],
				application_statuses: [],
			}
			payload.dictionaries.forEach( (el) => {
				if( el.this_means === 'ENTITY_COUNTRY') {
					dictionaries.countries.push({...el, value: el.dict_id, label: el.lang_en})
				} else if(el.this_means === 'ENTITY_STATE') {
					dictionaries.states.push({...el, value: el.dict_id, label: el.lang_en})
				} else if(el.this_means === 'ENTITY_DRIVER_LICENSE_CLASS') {
					dictionaries.driver_license_classes.push({...el, value: el.dict_id, label: el.lang_en})
				} else if(el.this_means === 'ENTITY_ENDORSEMENTS') {
					dictionaries.endorsements.push({...el, value: el.dict_id, label: el.lang_en})
				} else if(el.this_means === 'VEHICLE_TYPE') {
					dictionaries.vehicle_types.push({...el, value: el.dict_id, label: el.lang_en})
				} else if(el.this_means === 'COMPANY_ROLES') {
					dictionaries.company_roles.push({...el, value: el.dict_id, label: el.lang_en})
				} else if(el.this_means === 'SYSTEM_STATUSES') {
					dictionaries.system_statuses.push({...el, value: el.dict_id, label: el.lang_en})
				} else if(el.this_means === 'APPLICATION_STATUS') {
					dictionaries.application_statuses.push({...el, value: el.dict_id, label: el.lang_en})
				}
			})
			return {
				...state,
				dictionaries,
			};
		}
		case allConstants.LOAD_APLICATIONS_SUCCESS: {
			return {
				...state,
				applications: payload.applications,
			};
		}
		case allConstants.LOAD_COMPANY_WORKERS_SUCCESS: {
			return {
				...state,
				companyWorkers: payload.companyWorkers,
			};
		}
		case allConstants.SET_APPLICATION_STATUS_SUCCESS: {
			const application = payload.owner_id ? (state.applications.find( (app) => app.id == payload.owner_id ) || {}) : {};
			const statusData_ = payload.req_status ? (state.dictionaries.application_statuses.find( (st) => st.code == payload.req_status ) || {}) : {};
			application.applic_status = statusData_.dict_id;
			application.applic_status_description = payload.status_description;
			application.applic_time = (new Date()).toISOString();
			const newApplications = [
				...state.applications,
				application
			]
			return {
				...state,
				applications: newApplications
			};
		}
		case allConstants.ACTION_FAILURE: {
			const newAlertModal = {...payload};
			return {
				...state,
				alertModal: newAlertModal
			};
		}
		case allConstants.SET_ALERT_MODAL: {
			const newAlertModal = {...payload};
			return {
				...state,
				alertModal: newAlertModal
			};
		}
		case allConstants.CLEAR_ALERT_MODAL: {
			const newAlertModal = {...defAlertModal};
			return {
				...state,
				alertModal: newAlertModal
			};
		}
		default:
			return state;
	}
}
