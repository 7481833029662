import React from "react";
import { useSelector } from "react-redux";

import { STORE } from '../../../_redux/types';
import { AlertLight } from "../../../components/AlertLight";
import { BtnText } from "../../../components/BtnText";
import { IconNavigateBefore } from "../../../icons/IconNavigateBefore";

import "./style.less";

export const RegVerify = ({previous}) => {
  const { company_information } = useSelector((state: STORE) => state.appData);
  return (
    <div className="regVerify">
      <div className="screen-title">
        <p className="title">Verify and Activate Your Account</p>
        <p className="subtitle">
          <span className="subtitle-text">
            A Verification link has been sent to
            <br />
          </span>
          <span className="subtitle-email">{company_information.Work_E_mail.value}</span>
        </p>
      </div>
      <div className="overlap-group-wrapper">
        <div className="overlap-group">
          <img className="IconEmailLink" alt="Frame" src="/img/IconEmailLink.png" />
        </div>
      </div>
      <div className="check_inbox">
        <p className="check_inbox-title">Please check your inbox and click a link for Get Started.</p>
        <div className="check_inbox-alert">
          <AlertLight />
        </div>
      </div>
      <div className="button-wrapper">
        <BtnText
          handleClick={ () => {previous()}}
          submitText={"Enter Other E-mail"}
          startIcon={<IconNavigateBefore color="#3749E5"/>}
        />
      </div>
    </div>
  );
};
