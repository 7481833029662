/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconVisibility27 = ({ color = "#3749E5", className }) => {
  return (
    <svg
      className={`icon-visibility-27 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M8 4.33333C10.5267 4.33333 12.78 5.75333 13.88 8C12.78 10.2467 10.5267 11.6667 8 11.6667C5.47333 11.6667 3.22 10.2467 2.12 8C3.22 5.75333 5.47333 4.33333 8 4.33333ZM8 3C4.66667 3 1.82 5.07333 0.666667 8C1.82 10.9267 4.66667 13 8 13C11.3333 13 14.18 10.9267 15.3333 8C14.18 5.07333 11.3333 3 8 3ZM8 6.33333C8.92 6.33333 9.66667 7.08 9.66667 8C9.66667 8.92 8.92 9.66667 8 9.66667C7.08 9.66667 6.33333 8.92 6.33333 8C6.33333 7.08 7.08 6.33333 8 6.33333ZM8 5C6.34667 5 5 6.34667 5 8C5 9.65333 6.34667 11 8 11C9.65333 11 11 9.65333 11 8C11 6.34667 9.65333 5 8 5Z"
        fill={color}
      />
    </svg>
  );
};

IconVisibility27.propTypes = {
  color: PropTypes.string,
};
