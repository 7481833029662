
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { HashRouter, BrowserRouter } from 'react-router-dom';

import { Dispatch } from "./_redux/types";
import { allConstants } from "./_redux/_constants";
import { AppRoutes } from "./AppRoutes";
import { ModalLayout } from "./components/ModalLayout/ModalLayout";


type PropsFromRedux = {
}
const Router = ({ children }) => {
    const isProduction = process.env.NODE_ENV === 'production';

    if (isProduction) return <HashRouter>{children}</HashRouter>;

    return <BrowserRouter>{children}</BrowserRouter>;
};

function App({}: PropsFromRedux) {
  const dispatch: Dispatch = useDispatch();
  const {
    openModalA,
    status,
    statusText,
    statusData,
  } = useSelector((state: any) => state.appData.alertModal);

  return (
    <div className="mainScreen">
      <Router>
        <AppRoutes/>
      </Router>
      <ModalLayout
        open={openModalA}
        onClose={() => {
          dispatch({
            type: allConstants.CLEAR_ALERT_MODAL,
            payload: {}
          });
        }}
        title={"Error of request"}
      >
        <div className="modal-help-wrap modal-alert">
          <p>It seems that something went wrong</p>
          <p className="">
            {'For a faster resolution of the issue, you can contact us at '}
            <a href="mailto:help@oculusfreight.com">help@oculusfreight.com</a>
          </p>
          <p>The Oculus Freight team will try to respond to you as quickly as possible.</p>
          <p>Here is the error information.</p>
          <p>See the developer console for more details</p>
          <p className="">{statusText}</p>
          <p className="text-wrap">{JSON.stringify(statusData)}</p>
        </div>
      </ModalLayout>
    </div>
  );
}

export { App };
