import React from "react";

import { TableHeadItem } from "../TableHeadItem";

import "./style.less";

export const TableHead = ({tableHeadList}) => {

  return (
    <div className="table-head">
      {tableHeadList.map( (tableHeadItem, i) => {
        return <div key={i} className={`table-head-item-wrapper ${i === 0 ? 'table-head-item-wrapper-border-right' : '' } ${tableHeadItem.containerClass || ''}`}>
          <TableHeadItem
            className={`design-component-instance-node ${i === 0 ? 'px-3' : ''}`}
            sort={false}
            text={tableHeadItem.text}
          />
        </div>
      })}
    </div>
  );
};
