import "./style.less";

import React from "react";

export const DrawerLayout = ({ title = "Details", children, containerClasses = "" }) => {
  return (
    <div className={`DrawerLayout ${containerClasses}`}>
      <div className="drawer-wrap h-100">
        <p className="drawer-p">{title}</p>
        <div className="drawer-content">{children}</div>
      </div>
    </div>
  );
};
