
import React from "react";

export const DriverInfoBlock = ({
  blockTitle,
  items
}: {
  blockTitle: string,
  items?: any
}) => {
  return (
    <div className={`mb-3`} style={{}}>
      <div className={`text-main-16 ${""}`}>{blockTitle}</div>
      <div className="brd mt-2">
        {items?.map((item, i) => {
          return <div className="" key={i}>
            {item.subtitle && <div className="text-main-12-16-dark mb-3">{item.subtitle}</div>}
            <div className="column-gap-8">
              {item.subrows.map((itemRow, i) => {
                return <div className="row" key={i}>
                  <div className="col-4 text-gray-12">{itemRow.field_name}:</div>
                  {itemRow.field_el ? itemRow.field_el : <div className={`col-6 col text-main-13 font-w-600 ${""}`}>{itemRow.field_value} </div>}
                </div>
              })}
            </div>
          </div>
        })}
      </div>
    </div>
  );
};
