import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate  } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { PasswordRules } from '../../../components/PasswordRules';
import { BtnText } from "../../../components/BtnText";
import { BtnRegistration } from '../../../components/BtnRegistration';
import { AlertLight } from "../../../components/AlertLight";
import { Dispatch, STORE, IObj } from '../../../_redux/types';
import { userActions } from '../../../_redux/_actions';
import { allConstants } from '../../../_redux/_constants/index';
import { validations, validate, noEmptyValidate } from '../../../_redux/validations';
import { IconVisibility15 } from "../../../icons/IconVisibility15";
import { IconVisibility19 } from "../../../icons/IconVisibility19";

import "./style.less";

type Props = {
  go_to_login: () => void,
  pageNum?: number
}

export const ForgotPassword = ({
  go_to_login,
  pageNum = 0
}: Props) => {
  const [searchParams] = useSearchParams();
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const {resetEmail} = useSelector((state: STORE) => state.appData.authData);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [typePassword, setTypePassword] = useState("password");
  const [typeConfirm, setTypeConfirm] = useState("password");

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const [validationState, setValidationState] = useState({
    isValidLength: false,
    hasUpperAndLowercase: false,
    hasNumber: false,
    isValidConfirm: false,
  });
  
  useEffect(() => {
    setAttemptedSubmit(false);
  }, [password, confirmPassword]);

  const [emailErr, setEmailErr] = useState("");

  const [pageNumber, setPageNumber] = useState(pageNum); 
  const [btnText, setBtnText] = useState('Send Instructions');

	const set_key = (key2: string, value: string | null | IObj) => {
    dispatch({
      type: allConstants.SET_KEY,
      payload: {
        key1: 'authData',
        key2,
        value
      }
    });
	};

  const content_item = useMemo(() => {
    let content: any = {
      title: 'Reset password',
      subtitle: "Enter your email address below and we'll send an email with instructions to reset you password.",
      content: ({email}) => <div className="">
        <TextField
          value={email}
          onChange={(e) => {
            let errtext = validate(e.target.value, validations["company_information.Work_E_mail"]);
            setEmailErr(errtext);
            set_key('resetEmail', {
              value: e.target.value,
              isCorrect: !errtext,
              helperText: errtext,
            })
          }}
          required
          fullWidth
          id="E-mail"
          className="col-12"
          label="E-mail"
          placeholder="Your e-mail"
          InputProps={{
            startAdornment: <InputAdornment position="start"></InputAdornment>,
          }}
          helperText={resetEmail.helperText}
          error={!resetEmail.isCorrect}
        />
      </div>
    };
    switch (pageNumber) {
      case 1: {
        setBtnText('Resend Instructions')
        content = {
          title: 'Check your mail',
          subtitle: `We have sent a password recover instruction to ${resetEmail.value}.`,
          content: () => <div className="">
            <AlertLight />
          </div>
        }
        break;
      }
      case 2: {
        setBtnText('Set new password')
        content = {
          title: 'Reset password',
          subtitle: "Come up with a new password.",
          content: ({password, typePassword, validationState, confirmPassword, typeConfirm}) => <div className="column-gap-32">
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              id="Password"
              className=""
              label="Password"
              placeholder=""
              type ={typePassword}
              InputProps={{
                endAdornment: <InputAdornment
                  position="end"
                  onClick={() => {
                    setTypePassword(typePassword === "password" ? "text" : "password");
                  }}
                  sx={{
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                >
                  {typePassword === "password" ? <IconVisibility15 className="icon-visibility"/>
                    : <IconVisibility19 className="icon-visibility"/>}
                </InputAdornment>,
              }}
            />
            <TextField
              required
              fullWidth
              id="Confirm_Password"
              className={`${validationState.isValidLength
                && validationState.hasUpperAndLowercase
                && validationState.hasNumber
                && !validationState.isValidConfirm ? 'error' : ''}`}
              label="Confirm Password"
              placeholder=""
              type ={typeConfirm}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment
                  position="end"
                  onClick={() => {
                    setTypeConfirm(typeConfirm === "password" ? "text" : "password");
                  }}
                  sx={{
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                >
                  {typeConfirm === "password" ? <IconVisibility15 className="icon-visibility"/>
                    : <IconVisibility19 className="icon-visibility"/>}
                </InputAdornment>,
              }}
            />
            <PasswordRules
              password={password}
              confirmPassword={confirmPassword}
              attemptedSubmit={attemptedSubmit}
              setCheckingRule={setCheckingRule}
            />
          </div>
        }
        break;
      }
      default: 0;
    }
    return content;
  }, [pageNumber, resetEmail]);

  const setCheckingRule = (newValidCheck) => {
    setValidationState(newValidCheck);
  };

  const submitBtn = async () => {
    setAttemptedSubmit(true);
    if(pageNumber === 0) {
      const res = await handleSendInstructions();
      if(res?.status === 'ok') {
        setPageNumber(1);
      }
      setAttemptedSubmit(false);
    } else if(pageNumber === 1) {
      const res = await handleSendInstructions();
      setAttemptedSubmit(false);
    } else {
      await handleResetPassword()
    }
  };

  const handleSendInstructions = async () => {
    if (!emailErr) {
      return await dispatch(userActions.resetPassword({
        action: 'send_instructions',
        e_mail: resetEmail.value,
      })) as any;
    } else {
      console.log("Form has errors or incomplete, cannot proceed.");
    }
  };

  const handleResetPassword = async () => {
    if(validationState.isValidLength && validationState.hasUpperAndLowercase && validationState.hasNumber && validationState.isValidConfirm) {
      const res: any = await dispatch(userActions.resetPassword({
        action: 'reset_password',
        params: searchParams.get("params"),
        pwd: password,
      })) as any;
      if(res?.status === 'ok') {
        navigate("/login", { replace: true });
        go_to_login();
      }
    }
  };

  return (
    <div className="forgot-password">
      <div className="form-header">
        <div className="title">{content_item.title}</div>
        <p className="subtitle">{content_item.subtitle}</p>
      </div>
      {content_item.content({
        email: resetEmail.value,
        password,
        typePassword,
        validationState,
        confirmPassword,
        typeConfirm
      })}
      <div className="">
        <BtnRegistration
          submitText={btnText}
          onClick={() => {submitBtn()}}
          classes={'w-100'}
        />
      </div>
      {pageNumber === 0 && <div className="m">
        <BtnText
          submitText={"Go to login"}
          handleClick={ () => {
            dispatch({
              type: allConstants.SET_KEY,
              payload: { key1: "loginPageType", value: 'login'},
            });
          }}
        />
      </div>}
      {pageNumber === 1 && <div className="d-flex justify-content-center">
        <BtnText
          handleClick={ () => {setPageNumber(0);}}
          submitText={"Try another email address"}
        />
      </div>}
    </div>
  );
};
