import React, { useMemo } from 'react';

import { StaticDrawer } from '../Drawer/Drawer';
import { DrawerLayout } from '../DrawerLayout/DrawerLayout';
import './style.less';

import IconButton from '@mui/material/IconButton';
import { IconNavigateNext } from '../../icons/IconNavigateNext';
import { ChekItem, ChekItemRequired } from '../ChekItems/ChekItems';

export const QualificationTab = ({ applic = {} }: any) => {

  const items_licenses_elements = useMemo(() => {
    let items_licenses: any = applic.driver_licenses?.length ? applic.driver_licenses.map( (license, i) => {
      return <>
        <ChekItem
          title="Front of driver license"
          subTitle={license?.front_of_license_content_name}
          data={license}
          key_id={'img_front_of_license'}
          key_time={'front_of_license_content_create_time'}
        />
        <ChekItem
          title="Back of driver license"
          subTitle={license?.back_of_license_content_name}
          data={license}
          key_id={'img_back_of_license'}
          key_time={'back_of_license_content_create_time'}
        />
      </>
    }) : 'No licenses available.';
    return items_licenses;
  }, [applic.driver_licenses])

  return (
    <div className="QualificationTab">
      <div className="d-flex">
        {/* tyt ecran */}
        <div className="screen">
          <p className="qualification-title">Qualification Checklist</p>
          <div className="pb-3">
            <p className="title">Medical Information</p>
            <div className="chek-wrap">
              <ChekItem
                title="Medical Card"
                subTitle={applic?.med_card_content_name}
                data={applic}
                key_id={'medical_card'}
                key_time={'med_card_upload_time'}
              />
            </div>
          </div>
          <div className="pb-3">
            <p className="title">Driver License Information</p>
            <div className="custom-row row">
              {items_licenses_elements}
            </div>
          </div>
          <div className="pb-3">
            <p className="title">Driver Qualification File</p>
            <div className="custom-row row">
              <ChekItem title="Inquiry into Driving Record (Preceding 3 years)" />
              <ChekItem title="MVR" />
              <ChekItem title="PSP" />
              <ChekItem title="Good Faith Effort Document (Required)" />
              <ChekItem title="Road Test or Copy of Drivers License in Lieu of Road Test" />
              <ChekItem title="Copy of Medical Certificate (Long Form suggested)" />
              <ChekItem title="Medical Examiner's NationalRegistry Verification" />
              <ChekItem title="Commercial Driver's LicenseInformation System (CDLIS) Report" />
              <ChekItemRequired />
            </div>
          </div>
        </div>
        {/* tyt drawer */}
        {/* <div className="screen2">
          <DrawerLayout>
            <div className="drawer-content">
              <div className="drawer-subtitle">
                Please select <br></br>checklist item
              </div>
            </div>
          </DrawerLayout>
          <StaticDrawer />
        </div> */}
      </div>
    </div>
  );
};
