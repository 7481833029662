import React from "react";

import { TableFooter } from "../TableFooter";
import { Row } from "../Row";
import { IconNavigateNext } from "../../icons/IconNavigateNext";
import { IconNavigateBefore } from "../../icons/IconNavigateBefore/IconNavigateBefore";
import { TableHead } from "../TableHead";

// import "./style.less";

export const TableMain = ({ tableHeadList, tableColumns, drivers }) => {
  return (
    <div className="table mb-0">
      <TableHead tableHeadList={tableHeadList} />
      <div className="table-body">
        <div className="rows">
          {drivers.map((driver, i) => {
            return (
              <Row
                key={i}
                columnsList={tableHeadList}
                tableColumns={tableColumns}
                item={driver}
              />
            );
          })}
        </div>
      </div>
      <TableFooter listLength={drivers.length} />
    </div>
  );
};
