import React from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { MySelect } from "../MySelect";
import { STORE } from "../../_redux/types";

// @ts-ignore
import sprite from "../../icons/sprite.svg";

import "./style.less";

export const CompanySettings = () => {
  const { 
		company_id,
		First_Name,
		Last_Name,
		Phone_Number,
		Work_E_mail,
		Legal_Name,
		Phisical_Adress,
		MC_Number,
		USDOT_Number } = useSelector((state: STORE) => state.appData.company_information);
  return (
    <div className="company-settings screen-body p-4">
      <div className="screen">
        <div className="pb-4 text-main-24-32">Company settings</div>
        <div className="form-invite h-100 row">
          <div className="col-6 row mx-0">
            <div className="col-12 text-main-16 mb-4 px-0">General Information</div>
            <TextField
              disabled
              required
              fullWidth
              id="first_name"
              className="col-6 pr-2"
              label="Owner first name"
              placeholder=""
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={First_Name.value}
            />
            <TextField
              disabled
              required
              fullWidth
              id="last_name"
              className="col-6 pl-2"
              label="Owner last Name"
              placeholder=""
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={Last_Name.value}
            />
          </div>
          <div className="col-6 row mx-0">
            <div className="col-12 text-main-16 mb-4 px-0">Contacts Information</div>
            <TextField
              disabled
              required
              fullWidth
              id="e_mail"
              className="col-6 pr-2"
              label="Main registration e-mail"
              placeholder=""
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={Work_E_mail.value}
            />
            <TextField
              disabled
              required
              fullWidth
              id="phone-number"
              className="col-6 px-2"
              label="Main registration phone Number"
              placeholder="+1"
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={Phone_Number.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
