
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { STORE, Dictionary } from "../../_redux/types";

import "./style.less";

type Props = {
  // status: "hiring" | "pending" | "new" | "discarded" | "draft" | "training" | "out_of_service",
  status: number,
  className?: string
  style?: any
}

export const StatusDriverApplicants = ({ status, className, style = {} }: Props) => {
  const {application_statuses} = useSelector((state: STORE) => state.appData.dictionaries);

  const statusData = useMemo(() => {
    const statusData_ = status ? (application_statuses.find( (st) => st.dict_id == status ) || {}) : {};
    return statusData_ as Dictionary;
  }, [status, application_statuses]);

  // ('${self.dictMap['APPLICATION_STATUS']}', 'application_status_reject', 'Відхилено', 'Reject'),
  // ('${self.dictMap['APPLICATION_STATUS']}', 'application_status_deactivate', 'Призупинено', 'Deactivate'),

  return (
    <div className={`driver-applicants ${className} ${status ? statusData.code.substring('application_status_'.length) : 'draft'}`}
      style={style}
    >
      <div className={`driver-status`}>
        {status ? statusData?.lang_en : "Draft"}

        {/* {status === "discarded" && <>DISCARDED</>}
        {status === "out_of_service" && <>OUT OF SERVICE</>} */}

      </div>
    </div>
  );
};
