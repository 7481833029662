import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

// кнопка стрелка
export const MyIconButton = ({onClick}) => {
  return (
    <IconButton aria-label="go back" 
      onClick={() => onClick && onClick()}>
      <ArrowBackIcon />
    </IconButton>
  );
};

// конпка добавить тег
export const AddTagButton = () => {
  return (
    <IconButton
      style={{
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        padding: "0",
        color: "#3749E5", // Цвет иконки и текста
        outline: "none",
      }}
      aria-label="add"
    >
      <AddIcon
        style={{
          fontSize: "medium",
        }}
      />
      Add Tag
    </IconButton>
  );
};
//кнопка удалить тег
export const DeleteTagButton = () => {
  return (
    <IconButton
      style={{
        width: "84px",
        height: "24px",
        borderRadius: "40px",
        background: "#FDF090",
        fontWeight: "500",
        fontSize: "12px",
        lineHeight: "16px",
        // padding: "0",
        color: "#000000", // Цвет иконки и текста
        display: "flex",
        gap: "6px",
        outline: "none",
      }}
      aria-label="cancel"
    >
      Tag#1
      <ClearIcon style={{ fontSize: "medium" }} />
    </IconButton>
  );
};

// export const
