
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { AlertLight } from "../../components/AlertLight";
import { BtnRegistration } from '../../components/BtnRegistration';
import { MySelect } from "../../components/MySelect";
import { IconsCheck24Px8 } from "../../icons/IconsCheck24Px8";
import { Dispatch, STORE } from "../../_redux/types";
import { userActions } from '../../_redux/_actions';
import { defDataValueStr } from '../../_redux/_reducers';
import { MyOptionType } from '../../_styles/byComponents/selecterStyle';
import { validations, validate, noEmptyValidate } from '../../_redux/validations';

import "./style.less";

type Props = {
  title: string,
  closeModal: () => void,
};

export const ModalRightContentInviteUser = ({title, closeModal}: Props) => {
  const dispatch: Dispatch = useDispatch();
  const {company_id} = useSelector((state: STORE) => state.appData.company_information);
  const { company_roles } = useSelector((state: STORE) => state.appData.dictionaries);

  const [firstName, setFirstName] = useState(defDataValueStr);
  const [lastName, setLastName] = useState(defDataValueStr);
  const [email, setEmail] = useState(defDataValueStr);
  const [phoneNumber, setPhoneNumber] = useState(defDataValueStr);
  const [role, setRole] = useState(defDataValueStr);

  async function createCompanyWorker() {
    const reqData = {
      company_id,
      e_mail: email.value,
      first_name: firstName.value,
      last_name: lastName.value,
      phone_number: phoneNumber.value,
      role: role.value,
    };

    const res = await dispatch(userActions.resetPassword({
      action: 'send_instructions',
      action_type: 'company_worker_create',
      ...reqData,
    })) as any;
    if(res?.status === 'ok') {
      closeModal();
    }
  }
  return (
    <div className="modal-invite-user">
      <div className="modal-head">
        <Button type="button" onClick={closeModal} className="button-close">
          <svg style={{ stroke: "#6B6D82" }} width="18" height="18">
            <use href={sprite + "#icon-x-close"} />
          </svg>
        </Button>
        <div className="modal-right-title">
          {title}
        </div>
      </div>
      <div className="modal-body">
        <div className="form-invite h-100">
          <div className="row mx-0 mb-4">
            <TextField
              required
              fullWidth
              id="first_name"
              className="col-6 pr-2"
              label="First name"
              placeholder=""
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={firstName.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const errorText = validate(value, validations["company_information.First_Name"]);
                const newCorrect = !errorText;
                setFirstName({
                  value: value,
                  isCorrect: newCorrect,
                  helperText: errorText,
                });
              }}
            />
            <TextField
              required
              fullWidth
              id="last_name"
              className="col-6 pl-2"
              label="Last Name"
              placeholder=""
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={lastName.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const errorText = validate(value, validations["company_information.Last_Name"]);
                const newCorrect = !errorText;
                setLastName({
                  value: value,
                  isCorrect: newCorrect,
                  helperText: errorText,
                });
              }}
            />
          </div>
          <div className="row mx-0">
            <TextField
              required
              fullWidth
              id="e_mail"
              className="col-3 pr-2"
              label="E-mail"
              placeholder=""
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={email.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const errorText = validate(value, validations["company_information.Work_E_mail"]);
                const newCorrect = !errorText;
                setEmail({
                  value: value,
                  isCorrect: newCorrect,
                  helperText: errorText,
                });
              }}
            />
            <TextField
              required
              fullWidth
              id="phone_number"
              className="col-3 px-2"
              label="Phone Number"
              placeholder="+1"
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
              value={phoneNumber.value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value;
                const errorText = validate(value, validations["company_information.Phone_Number"]);
                const newCorrect = !errorText;
                setPhoneNumber({
                  value: value,
                  isCorrect: newCorrect,
                  helperText: errorText,
                });
              }}
            />
            <MySelect
              options={company_roles as MyOptionType[]}
              changeSelect={(opt: any) => {
                const value = opt.value
                const errorText = noEmptyValidate(value);
                const newCorrect = !!errorText;
                setRole({
                  value: value,
                  isCorrect: newCorrect,
                  helperText: errorText,
                });
              }}
              value={role.value}
              id="role"
              classes={'col-6 pl-2'}
              placeholder={'Select'}
            />
          </div>
        </div>
        <div className="form-invite-helptext w-100">
          <AlertLight text={"An invitation link will be sent to the email"} classes={""}/>
        </div>
      </div>
      <div className="my-modal-footer">
        <div className="modal-buttons w-100">
          <Button variant="outlined" className="button-modal">
            Cancel
          </Button>
          <BtnRegistration
            submitText={'Submit'}
            icon={<IconsCheck24Px8
              className="icon mr-2"
              color={"#FFF"}
            />}
            onClick={() => {createCompanyWorker()}}
            classes={'padding-10-20-10-13'}
          />
        </div>
      </div>
    </div>
  );
};
