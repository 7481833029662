/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconCopy = ({ color = "#6B6D82", className }) => {
  return (
    <svg
      className={`icon-copy-30 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M14.2487 0.833008H4.2487C3.33203 0.833008 2.58203 1.58301 2.58203 2.49967V14.1663H4.2487V2.49967H14.2487V0.833008ZM16.7487 4.16634H7.58203C6.66536 4.16634 5.91536 4.91634 5.91536 5.83301V17.4997C5.91536 18.4163 6.66536 19.1663 7.58203 19.1663H16.7487C17.6654 19.1663 18.4154 18.4163 18.4154 17.4997V5.83301C18.4154 4.91634 17.6654 4.16634 16.7487 4.16634ZM16.7487 17.4997H7.58203V5.83301H16.7487V17.4997Z"
        fill={color}
      />
    </svg>
  );
};

IconCopy.propTypes = {
  color: PropTypes.string,
};
