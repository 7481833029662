import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { IconButton } from "@mui/material";

import { dateEdit } from "../../js/helpers";
import {
  applicant_signature_signed,
  alcohol_drug_signed,
  safety_performance_signed,
  PSP_driver_disclosure_signed,
  drug_alcohol_clearinghouse_consent_signed,
  alcohol_drug_testing_policy_signed,
  general_work_policy_signed,
  fair_credit_reporting_authorization_signed,
} from '../../_redux/_constants';
import { allActions } from '../../_redux/_actions';
import { Dispatch } from "../../_redux/types";
// @ts-ignore
import sprite from "../../icons/sprite.svg";
import "./style.less";

const emptyTitle = "_____";
const contentsSigned = [
  applicant_signature_signed,
  alcohol_drug_signed,
  safety_performance_signed,
  PSP_driver_disclosure_signed,
  drug_alcohol_clearinghouse_consent_signed,
  alcohol_drug_testing_policy_signed,
  general_work_policy_signed,
  fair_credit_reporting_authorization_signed,]

export const FileItem = ({title = emptyTitle, data = {}}: any) => {
  const dispatch: Dispatch = useDispatch();

  const upload_time = useMemo(() => {
    if(!data?.create_time) return "";
    const currentDateTime = Number(new Date(data?.create_time));
    const timeString = dateEdit(currentDateTime, false);
    return timeString;
  }, [data]);

  const isSigned = useMemo(() => {
    return contentsSigned.includes(data?.content_means);
  }, [data]);

  const titleByData = useMemo(() => {
    if(!data?.content_means) return title;
    if(data?.content_means === applicant_signature_signed) return "Applicant Signature"
    if(data?.content_means === alcohol_drug_signed) return "Pre-Employment Employee Alcohol & Drug Testatement"
    if(data?.content_means === safety_performance_signed) return "Safety Performance History Investigation"
    if(data?.content_means === PSP_driver_disclosure_signed) return "PSP Driver Disclosure & Authorization"
    if(data?.content_means === drug_alcohol_clearinghouse_consent_signed) return "Drug & Alcohol Clearinghouse Consent"
    if(data?.content_means === alcohol_drug_testing_policy_signed) return "Alcohol & Drug Testing Policy"
    if(data?.content_means === general_work_policy_signed) return "General Work Policy"
    if(data?.content_means === fair_credit_reporting_authorization_signed) return "Fair Credit Reporting Authorization"
    return title;
  }, [data]);

  async function loadPdfWithSigned() {
    const res: any = await allActions.loadPdfWithSigned(dispatch, data.content_id);
    
    const pdf_data = res.payload?.pdf?.data;
    if(pdf_data) {
      const buf = Buffer.from(pdf_data, 'base64');
      const blob = new Blob([buf as Buffer], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'test.pdf'
      link.click()
    }
  };

  async function deleteFiles() {
    const res: any = await allActions.deleteFiles(dispatch, [data.content_id]);
  };

  return (
    <div className="FileItem mb-2">
      <div className="file-container">
        <div>
          <svg width="32" height="32">
            <use href={sprite + "#icon-file"}></use>
          </svg>
        </div>
        <div className="file-content-wrap ">
          <div className="file-content ">
            <div className="file-title ">{titleByData}</div>
            <div className="info">
              <div>{upload_time}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
              >
                <circle cx="2" cy="2" r="2" fill="#E2E0E6" />
              </svg>
              {/* <div className="subtitle">32 kB</div> */}
            </div>
          </div>
          {data.content_name && <div className="description">
            {data.content_name}
          </div>}
        </div>
        <div className="">
          {!isSigned ? <IconButton className="file-icon-button" onClick={async () => {
            const userData = sessionStorage.getItem("userData");
            const userDataResult = JSON.parse(userData || "{}");
            // `${SERVER_URL}/images/${data?.medical_card}/${userDataResult.token}`
            // const imgUrl = `http://localhost:1234/images/${data?.medical_card}/${userDataResult.token}`
            const imgUrl = `images/${data?.content_id}/${userDataResult.token}`
            const res: any = await allActions.requestGet(imgUrl, {
              responseType: 'arraybuffer'
            });
            if(res) {
              let b64Response = btoa(
                new Uint8Array(res.data)
                  .reduce((data, byte) => data + String.fromCharCode(byte), '')
              );
  
              var image = new Image();
              image.src = `data:${res.headers['content-type']};base64, ` + b64Response;
  
              let w = window.open("",'_blank');
              w?.document.write(image.outerHTML);
              w?.document.close(); 
            }
          }}>
            <svg width="24" height="24">
              <use href={sprite + "#signed"}></use>
            </svg>
          </IconButton>
          : <IconButton className="file-icon-button" onClick={async () => {
            loadPdfWithSigned(); 
          }}>
            <svg width="24" height="24">
              <use href={sprite + "#icon-download"}></use>
            </svg>
          </IconButton>}
          <IconButton className="file-icon-button"
            onClick={deleteFiles}
          >
            <svg width="24" height="24">
              <use href={sprite + "#icon-trash"}></use>
            </svg>
          </IconButton>
        </div>
      </div>
    </div>
  );
};
