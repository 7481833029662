import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate  } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { Dispatch, STORE, IObj } from '../../../_redux/types';
import { userActions } from '../../../_redux/_actions';
import { allConstants } from '../../../_redux/_constants';
import { PasswordRules } from '../../../components/PasswordRules';
import { BtnRegistration } from '../../../components/BtnRegistration';
import { IconVisibility15 } from "../../../icons/IconVisibility15";
import { IconVisibility19 } from "../../../icons/IconVisibility19";

import "./style.less";

type Props = {
}

export const RegPassword = (props: Props) => {
  const {
  } = props;
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {reg_create_pass} = useSelector((state: STORE) => state.appData.authData);

	const set_key = (key2: string, value: string | null | IObj) => {
    dispatch({
      type: allConstants.SET_KEY,
      payload: {
        key1: 'authData',
        key2,
        value
      }
    });
	};

  const [confirmPassword, setConfirmPassword] = useState("");
  const [typePassword, setTypePassword] = useState("password");
  const [typeConfirm, setTypeConfirm] = useState("password");

  const [validationState, setValidationState] = useState({
    isValidLength: false,
    hasUpperAndLowercase: false,
    hasNumber: false,
    isValidConfirm: false,
  });

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  useEffect(() => {
    setAttemptedSubmit(false);
  }, [reg_create_pass.value, confirmPassword]);

  const setCheckingRule = (newValidCheck) => {
    setValidationState(newValidCheck);
  };

  const handleAttemptedSubmit = async () => {
    setAttemptedSubmit(true);
    if(validationState.isValidLength && validationState.hasUpperAndLowercase && validationState.hasNumber && validationState.isValidConfirm) {
      const res: any = await dispatch(userActions.registrationFinish(searchParams.get("params"), reg_create_pass.value)) as any;
      if(res?.status === 'ok') {
        dispatch({
          type: allConstants.SET_KEY,
          payload: { key1: "loginPageType", value: 'login'},
        });
        navigate("/login", { replace: true });
      }
    }
    setAttemptedSubmit(false);
  };

  return (
    <div className="regPassword">
      <div className="form-item screen-title">
        <div className="title">Create a Password</div>
        <p className="screen-subtitle">Please create your personal password.</p>
      </div>
      <div className="form-item">
        <TextField
          value={reg_create_pass.value}
          onChange={(e) => {
            set_key('reg_create_pass', {
              value: e.target.value,
              isCorrect: true,
              helperText: '',
            })
          }}
          required
          fullWidth
          id="Password"
          className=""
          label="Password"
          placeholder=""
          type ={typePassword}
          InputProps={{
            endAdornment: <InputAdornment
              position="end"
              onClick={() => {
                setTypePassword(typePassword === "password" ? "text" : "password");
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                }
              }}
            >
              {typePassword === "password" ? <IconVisibility15 className="icon-visibility"/>
                : <IconVisibility19 className="icon-visibility"/>}
            </InputAdornment>,
          }}
          helperText={reg_create_pass.helperText}
          error={!reg_create_pass.isCorrect}
        />
        <TextField
          required
          fullWidth
          id="Confirm_Password"
          className={`${validationState.isValidLength
            && validationState.hasUpperAndLowercase
            && validationState.hasNumber
            && !validationState.isValidConfirm ? 'error' : ''}`}
          label="Confirm Password"
          placeholder=""
          type ={typeConfirm}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment
              position="end"
              onClick={() => {
                setTypeConfirm(typeConfirm === "password" ? "text" : "password");
              }}
              sx={{
                "&:hover": {
                  cursor: "pointer"
                }
              }}
            >
              {typeConfirm === "password" ? <IconVisibility15 className="icon-visibility"/>
                : <IconVisibility19 className="icon-visibility"/>}
            </InputAdornment>,
          }}
        />
        <PasswordRules
          password={reg_create_pass.value}
          confirmPassword={confirmPassword}
          attemptedSubmit={attemptedSubmit}
          setCheckingRule={setCheckingRule}
        />
      </div>
      <div className="form-item">
        <BtnRegistration
          onClick={() => {handleAttemptedSubmit()}}
          submitText={'Create Account'}
          classes={'w-100'}
        />
      </div>
    </div>
  );
};
