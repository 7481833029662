
import React from "react";

type Props = {
  color?: string,
  opacity?: string,
  className?: string,
}

export const IconPlus = ({ color = "#000965", opacity = "unset", className = "" }: Props) => {
  return (
    <svg
      className={`icon-plus ${className}`}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15 9.75H9.75V15H8.25V9.75H3V8.25H8.25V3H9.75V8.25H15V9.75Z"
        fill={color}
        opacity={opacity}
      />
    </svg>
  );
};
