
import React, { useState, ChangeEvent } from 'react';
import Select from 'react-select';

import { MyOptionType, selectCustomStylesFun } from '../_styles/byComponents/selecterStyle';

type Props = {
  id?: string,
  options: MyOptionType[],
  changeSelect?: (selectedOpt: any) => void,
  value: string | number,
  classes?: string,
  placeholder?: string,
}

const MySelect = (props: Props) => {
  const {
    id,
    options,
    changeSelect,
    value,
    classes,
    placeholder,
  } = props;

  return (
    <Select
      id={id}
      menuPlacement="auto"
      // menuIsOpen={true}
      styles={selectCustomStylesFun()}
      className={`sortSelecter inputStyle ${classes}`}
      value={options.find( opt => opt.value === value)}
      onChange={opt => changeSelect && changeSelect(opt)}
      options={options}
      placeholder={placeholder}
    />
  );
};

export { MySelect };