
import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import { Button } from "@mui/base";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
// import { ButtonClose } from "components/ModalBoard/ModalBoard.styled";

import "./style.css";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: 2,
  border: "1px solid rgba(190, 219, 176, 0.50)",
  background: "#FFFFFF",
  borderBottom: "1px solid var(--M3-sys-light-outline-variant, #E2E0E6)",
};

const titleStyles = {
  fontFamily: "Roboto",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "32px",
};

export const ModalLayout = ({ title, open, onClose, children }) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyles}>
          <div className="modal-head">
            <Typography variant="h2" sx={titleStyles}>
              {title}
            </Typography>
            <Button type="button" onClick={onClose} className="button-close">
              <svg style={{ stroke: "#6B6D82" }} width="18" height="18">
                <use href={sprite + "#icon-x-close"} />
              </svg>
            </Button>
          </div>

          {children}
        </Box>
      </Modal>
    </>
  );
};
