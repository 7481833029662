import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@mui/material";

import { ModalRightContentInviteUser } from "../ModalRightContentInviteUser";
import { allConstants } from "../../_redux/_constants";

import "./style.less";

export const ModalLayoutRight = () => {
  const dispatch = useDispatch();
  const { open } = useSelector((state: any) => state.appData.modalData);

  const closeModal = () => {
    dispatch({
      type: allConstants.SET_MODAL_DATA,
      payload: { open: false },
    });
  };

  return (
    <Modal
      open={open} // modalRightOpened
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="my-modal-content">
        <ModalRightContentInviteUser
          title={"Invite User"}
          closeModal={closeModal}
        />
        {/* {modalRightContent} */}
      </div>
    </Modal>
  );
};
