
import React from "react";

export const IconSettings = ({ color = "#6B6D82", className, classNameSvg }) => {
  return (
    <div className={`${className}`}>
      <svg
        className={`${classNameSvg}`}
        fill="none"
        height="25"
        viewBox="0 0 24 25"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="path"
          d="M19.1421 12.9697C19.1821 12.6697 19.2021 12.3597 19.2021 12.0297C19.2021 11.7097 19.1821 11.3897 19.1321 11.0897L21.1621 9.50969C21.3421 9.36969 21.3921 9.09969 21.2821 8.89969L19.3621 5.57969C19.2421 5.35969 18.9921 5.28969 18.7721 5.35969L16.3821 6.31969C15.8821 5.93969 15.3521 5.61969 14.7621 5.37969L14.4021 2.83969C14.3621 2.59969 14.1621 2.42969 13.9221 2.42969H10.0821C9.84206 2.42969 9.65206 2.59969 9.61206 2.83969L9.25206 5.37969C8.66206 5.61969 8.12206 5.94969 7.63206 6.31969L5.24206 5.35969C5.02206 5.27969 4.77206 5.35969 4.65206 5.57969L2.74206 8.89969C2.62206 9.10969 2.66206 9.36969 2.86206 9.50969L4.89206 11.0897C4.84206 11.3897 4.80206 11.7197 4.80206 12.0297C4.80206 12.3397 4.82206 12.6697 4.87206 12.9697L2.84206 14.5497C2.66206 14.6897 2.61206 14.9597 2.72206 15.1597L4.64206 18.4797C4.76206 18.6997 5.01206 18.7697 5.23206 18.6997L7.62206 17.7397C8.12206 18.1197 8.65206 18.4397 9.24206 18.6797L9.60206 21.2197C9.65206 21.4597 9.84206 21.6297 10.0821 21.6297H13.9221C14.1621 21.6297 14.3621 21.4597 14.3921 21.2197L14.7521 18.6797C15.3421 18.4397 15.8821 18.1197 16.3721 17.7397L18.7621 18.6997C18.9821 18.7797 19.2321 18.6997 19.3521 18.4797L21.2721 15.1597C21.3921 14.9397 21.3421 14.6897 21.1521 14.5497L19.1421 12.9697ZM12.0021 15.6297C10.0221 15.6297 8.40206 14.0097 8.40206 12.0297C8.40206 10.0497 10.0221 8.42969 12.0021 8.42969C13.9821 8.42969 15.6021 10.0497 15.6021 12.0297C15.6021 14.0097 13.9821 15.6297 12.0021 15.6297Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
