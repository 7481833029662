import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { DrawerLayout } from "../DrawerLayout/DrawerLayout";
import { MySelect } from "../../components/MySelect";
import { MyOptionType } from '../../_styles/byComponents/selecterStyle';
import { Dispatch, STORE, CompanyWorker } from "../../_redux/types";

// @ts-ignore
import sprite from "../../icons/sprite.svg";

import "./style.less";

type Props = {
  worker: CompanyWorker
}

export const CompanyWorkerData = ({worker}: Props) => {
  const { company_roles } = useSelector((state: STORE) => state.appData.dictionaries);
  return (
    <div className="company-worker-data">
      <div className="d-flex h-100">
        {/* tyt ecran */}
        <div className="screen">
          <div className="pb-4 text-main-24-32">Overview</div>
          <div className="file-wrap pt-4">
            <div className="form-invite h-100 row">
              <div className="col-6 row mx-0">
                <div className="col-12 text-main-16 mb-4 px-0">General Information</div>
                <TextField
                  disabled
                  required
                  fullWidth
                  id="first_name"
                  className="col-6 pr-2"
                  label="First name"
                  placeholder=""
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  value={worker.first_name}
                  // onChange={(e) => handleChange("Work_E_mail", e.target.value)}
                />
                <TextField
                  disabled
                  required
                  fullWidth
                  id="last_name"
                  className="col-6 pl-2"
                  label="Last Name"
                  placeholder=""
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  value={worker.last_name}
                />
                <TextField
                  disabled
                  required
                  fullWidth
                  id="role"
                  className="col-12 mt-4"
                  label="Role"
                  placeholder=""
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  value={company_roles.find( (role) => role.dict_id == +worker.role)?.lang_en}
                />
                {/* <MySelect
                  options={company_roles as MyOptionType[]}
                  changeSelect={(opt: any) => {
                  }}
                  value={+worker.role}
                  id="role"
                  classes={'col-12 mt-4'}
                  placeholder={'Select'}
                /> */}
              </div>
              <div className="col-6 row mx-0">
                <div className="col-12 text-main-16 mb-4 px-0">Contacts Information</div>
                <TextField
                  disabled
                  required
                  fullWidth
                  id="e_mail"
                  className="col-6 pr-2"
                  label="E-mail"
                  placeholder=""
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  value={worker.e_mail}
                />
                <TextField
                  disabled
                  required
                  fullWidth
                  id="phone_number"
                  className="col-6 px-2"
                  label="Phone Number"
                  placeholder="+1"
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                  value={worker.phone_number}
                />
              </div>
            </div>
          </div>
        </div>
        {/* tyt drawer */}
        {/* <DrawerLayout title="Activity">
          <div className="file-drawer-wrap">
          </div>
        </DrawerLayout> */}
      </div>
    </div>
  );
};
