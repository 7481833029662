import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route } from 'react-router-dom';

import { paths } from './_redux/_constants';
import { Dispatch } from "./_redux/types";
import { allConstants } from './_redux/_constants';
import { allActions } from './_redux/_actions';
import { CompanyWorkersList } from "./screens/CompanyWorkersList";
import { ApplicantsListScreen } from "./screens/ApplicantsListScreen";
import { VendorsScreen } from "./screens/VendorsScreen";
import { ApplicantScreen } from "./screens/ApplicantScreen";
import { Header } from "./components/Header";
import { NavigationRail } from "./components/NavigationRail";
import { ModalLayoutRight } from "./components/ModalLayoutRight";
import { CompanySettings } from "./components/CompanySettings";
import { CompanyWorkerScreen } from "./screens/CompanyWorkerScreen";

import "./containerStyles.less";

const Container = ({}) => {
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const get_dictionaries_res: any = await dispatch(allActions.loadAndSetInRedux(allConstants.LOAD_DICTIONARIES_SUCCESS, {
        action: 'get_dictionaries'
      }));
    })();
  }, []);

  return (
    <div className="screen-container">
      <div className="navigation-rail-container">
        <NavigationRail />
      </div>
      <div className="header-container">
        <Header />
        <Routes>
          <Route path={`/${paths.COMPANY_WORKERS_LIST}/:companyWorkerId`} element={<CompanyWorkerScreen />}/>
          <Route path={`/${paths.COMPANY_WORKERS_LIST}`} element={<CompanyWorkersList />}/>
          <Route path={`/${paths.COMPANY_SETTINGS}`} element={<CompanySettings />}/>
          <Route path={`/${paths.APPLICANTS}/:applicantId`} element={<ApplicantScreen />}/>
          <Route path='/' element={<ApplicantsListScreen />}/>
        </Routes>

        {/* <VendorsScreen /> */}
      </div>
      <ModalLayoutRight />
    </div>
  );
};

export { Container };
