import React, { useState } from "react";

import { Modal } from "../../components/Modal";
import { IconHelp } from "../../icons/IconHelp";
import { ModalLayout } from "../ModalLayout/ModalLayout";

import "./style.css";

export const HelpDeskButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div
        className="button-helpdesk"
        onClick={openModal}
        onMouseEnter={() => setIsHovered(true)} // Встановлюємо стан при наведенні курсора
        onMouseLeave={() => setIsHovered(false)} // Скидаємо стан при знятті курсора
      >
        <IconHelp className="icon-instance-node-2" />
        {isHovered && ( // Тепер тултип з'являється тільки при наведенні курсора
          <div className="tooltip-helpdesk">
            <div className="tooltip-text">Help Desk</div>
            <div className="tooltip-arrow"></div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <ModalLayout
          open={isModalOpen}
          onClose={closeModal}
          title={"Help Desk"}
        >
          <div className="modal-help-wrap">
            <p>
              If you have any issues or questions about our product, please
              write to us at
            </p>
            <p className="help-email">
              <a href="mailto:help@oculusfreight.com">help@oculusfreight.com</a>
            </p>
            <p>
              The Oculus Freight team will try to respond to you as quickly as
              possible.
            </p>
          </div>
        </ModalLayout>
      )}
    </>
  );
};
