

import { allConstants } from '../_constants';
import { mainService } from '../_requests';

export const allActions = {
	loadAndSetInRedux,
	getCompanyWorkers,
    companyWorkerDeactivate,
    companyWorkerActivate,
    applicationSetStatusNew,
    deleteFiles,
    requestGet,
    uploadImg,
    loadPdfWithSigned,
};

function loadAndSetInRedux(action_type_success, req = {}, action_type_failure = allConstants.ACTION_FAILURE) {
    return async dispatch => {
        dispatch({
            type: allConstants.ACTION_REQUEST,
        });
        return mainService.mainRequest('command', req).then( (data) => {
            if (data.status === 'error') {
                const errorConstant = action_type_failure;
                const statusText = typeof data?.error?.errorText === 'string' ? (
                    data?.error?.errorText === 'WRONG_FORMAT' ? 'WRONG FORMAT' : data?.error?.errorText
                ) : (data?.error?.errorText?.message || data?.status);
                return dispatch({
                    type: errorConstant,
                    payload: {
                        ...data.error,
                        openModalA: true,
                        status: typeof data?.status === 'number' ? data.status : 500,
                        statusText: statusText,
                        statusData: {
                            ...req,
                            req_status: req.status,
                            ...data,
                        },
                    }
                });
            } else {
                return dispatch({
                    type: action_type_success,
                    payload: {...req, req_status: req.status, ...data, type: action_type_success}
                });
            }
        }).catch((err) => {
            const errorConstant = action_type_failure;
            dispatch({
                type: errorConstant,
                payload: err.error
            });
            return {...err};
        });
    };
};

async function getCompanyWorkers (dispatch, company_id) {
    return await dispatch(allActions.loadAndSetInRedux(allConstants.LOAD_COMPANY_WORKERS_SUCCESS, {
        action: 'get_company_workers',
        company_id
    }));
};

async function companyWorkerDeactivate (dispatch, company_worker_id) {
    return await dispatch(allActions.loadAndSetInRedux(allConstants.COMPANY_WORKER_DEACTIVATE, {
        action: 'company_worker_deactivate',
        company_worker_id
    }));
};

async function companyWorkerActivate (dispatch, company_worker_id) {
    return await dispatch(allActions.loadAndSetInRedux(allConstants.COMPANY_WORKER_ACTIVATE, {
        action: 'company_worker_activate',
        company_worker_id
    }));
};

async function applicationSetStatusNew (dispatch, id, status, status_description) {
    return await dispatch(allActions.loadAndSetInRedux(allConstants.SET_APPLICATION_STATUS_SUCCESS, {
        action: 'set_application_status',
        owner_type: 'ENTITY_APPLICATION',
        owner_id: id,
        status,
        status_description
    }));
};

async function deleteFiles (dispatch, ids) {
    return await dispatch(allActions.loadAndSetInRedux(allConstants.ACTION_SUCCESS, {
        action: 'delete_files',
        ids_for_del: ids,
    }));
};

async function requestGet(requestPath, config) {
    return mainService.mainRequestGet(requestPath, config).then( (res) => {
        if (res?.data.status === 'error') {
        } else {
            return res;
        }
    }).catch((err) => {
        console.log("🚀 requestGet err:", err)
        return null;
    });
};

function uploadImg(uploadData) {
    const req = {
        fileData: {
            owner_type: uploadData.owner_type,
            owner_id: uploadData.owner_id,
            content: uploadData.image_data,
            content_type: uploadData.image_type,
            content_name: uploadData.image_name,
        },
        action: 'upload_file',
    };
    return async dispatch => {
        dispatch({
            type: allConstants.ACTION_REQUEST,
            payload: { req }
        });
        return mainService.mainRequest('command', req).then( (data) => {
            if (data.status === 'error') {
                const errorConstant = allConstants.ACTION_FAILURE;
                return dispatch({
                    type: errorConstant,
                    payload: {...data, ...data.error, type: allConstants.ACTION_SUCCESS}
                });
            } else {
                return dispatch({
                    type: allConstants.ACTION_SUCCESS,
                    payload: {...req, ...data, type: allConstants.ACTION_SUCCESS}
                });
            }
            return {...req, ...data};
        }).catch((err) => {
            const errorConstant = allConstants.ACTION_FAILURE;
            dispatch({
                type: errorConstant,
                payload: err.error
            });
            return {...req, ...err};
        });
    };
}

async function loadPdfWithSigned (dispatch, id) {
    return await dispatch(allActions.loadAndSetInRedux(allConstants.ACTION_SUCCESS, {
        action: 'load_pdf_with_signed',
        image_id: id,
    }));
};
