
import React from "react";

import "./style.less";

export const IconArrowUpward = ({ color = "black", className = "" }) => { // color="#C8C5CA" - gray
  return (
    <svg
      className={`icon-arrow-upward ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.16797 8.00033L4.10797 8.94033L7.83464 5.22033V13.3337H9.16797V5.22033L12.888 8.94699L13.8346 8.00033L8.5013 2.66699L3.16797 8.00033Z"
        fill={color}
      />
    </svg>
  );
};
