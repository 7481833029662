
import React from "react";

import { Check } from "../../icons/Check";
import { StyleAvatar } from "../../icons/StyleAvatar";

import "./style.less";

type Props = {
  userImage?: any,
  userName: string,
}

export const UserImagesUser = ({ userImage, userName }: Props) => {
  return (
    <div className="user-images-user-container"> {/* Додано контейнер для монограми та імені */}
      <div className={`user-images-user`}>
      {userImage ? <img className="IMG" alt="Img" src="/img/img.png" />
        : <div className="initial">{userName[0]}</div>
      }
      </div>
      {userName && <div className="user-name">{userName}</div>}
    </div>
  );
};

{/* <StyleAvatar className="style-avatar" /> */}
{/* <Check className="icon-check" /> */}

// UserImagesUser.propTypes = {
//   style: PropTypes.oneOf(["avatar", "check", "monogram", "photo"]),
// };
