/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconWarning = ({ color = "black", className }) => {
  return (
    <svg
      className={`icon-warning-1 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M10 5.40833L16.275 16.25H3.725L10 5.40833ZM10 2.08333L0.833336 17.9167H19.1667L10 2.08333ZM10.8333 13.75H9.16667V15.4167H10.8333V13.75ZM10.8333 8.74999H9.16667V12.0833H10.8333V8.74999Z"
        fill={color}
      />
    </svg>
  );
};

IconWarning.propTypes = {
  color: PropTypes.string,
};
