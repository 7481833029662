/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const IconCheckSmall4 = ({ color = "#1B6536", className }) => {
  return (
    <svg
      className={`icon-check-small-4 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M6 12.4L2 8.4L3.4 7L6 9.6L12.6 3L14 4.4L6 12.4Z" fill={color} />
    </svg>
  );
};

IconCheckSmall4.propTypes = {
  color: PropTypes.string,
};
