import React, { useState, useCallback } from "react";
import {useDropzone} from 'react-dropzone'

import { Button } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { IconTrash } from "../../icons/IconTrash";
import { IconButtonSmall } from "../../icons/IconButtonSmall";

import "./style.less";

export const Upload = ({
  text = "Supported Formats: JPG, PNG, GIF, PDF",
  value = {},
  saveToRedux,
  removeImg,
  upload,
}: any) => {

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    fileReader(acceptedFiles);
  }, [])

  function fileReader(acceptedFiles) {
    const reader = new FileReader();
    // const file = e.target.files[0];
    const file = acceptedFiles[0];
    reader.readAsDataURL(file);
    reader.onerror = error => console.log(error);

    // compression by canvas element in browser
    reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;
        if(img.complete){
          load();
        } else {
          img.onload = () => {
            load();
          }
        }
        async function load () {
            const elem = document.createElement('canvas');
            elem.width = img.width;
            elem.height = img.height;
            const ctx = elem.getContext('2d');
            ctx?.drawImage(img, 0, 0, img.width, img.height);

            // let k = 0.75; // default compression
            let k = 1
            while (k > 0.25) {
                const newFile: any = await makeSmaller(ctx, file, k);
                if (newFile?.size < 4000000) { // 4.0 MB limit made by http-api
                  reader.readAsDataURL(newFile);
                  reader.onload = (event) => {
                      event && saveToRedux && saveToRedux(event?.target?.result, newFile);
                    }
                    k = 0;
                } else {
                    k -= 0.1; // make more compression
                }
            }
        };
    };
  }

  function makeSmaller(ctx, file, quality) {
      return new Promise((resolve) => {
          ctx.canvas.toBlob((blob) => {
              resolve(new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now()
              }));
          }, file.type, quality);
      });
  }

  // const [fileUploaded, setFileUploaded] = useState(true);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className="Upload">
      <div className="upload design-component-instance-node-2">
        {/* блок додавання файла */}
        <div className="area" {...getRootProps()} >
          <input
            {...getInputProps()} 
            className={`upload-input`}
            type="file"
            accept="image/png, image/jpeg, image/gif"
            style={{ display: "none" }}
          />
          <svg width="32" height="32" className="icon-54">
            <use href={sprite + "#icon-upload"}></use>
          </svg>
          <div className="frame-4">
            <div className="text-wrapper-2">
              Click or Drag file here to Upload
            </div>
            <div className="supported-formats">{text}</div>
          </div>
        </div>
        {/* @ts-ignore */}
        {value.image_data && (
          <div className="file">
          {/* @ts-ignore */}
            <div className="text">{value.image_name}</div>
            <IconButtonSmall
              configuration="standard"
              icon={<IconTrash className="icon-trash button" color="#3749E5" />}
              onClick={() => {
                removeImg && removeImg();
              }}
              stateProp="enabled"
              tooltipClassName="icon-button-small-instance"
              tooltipText="Delete"
            />
          </div>
        )}
        {upload && <Button disabled={!value?.image_data} className="button-submit" onClick={upload}>
          <CheckIcon fontSize="small" />
          Submit
        </Button>}
        {/* блок скачати файл */}
        {/* коли цей блок буде дороблюватись то це треба винести в окрему компоненту */}
        {/* <div className="download-wrap">
          <p className="p-upload">
            Looking for a copy of all files on this driver? Click the button
            below to download a zip file containing all documents.
          </p>
          <Button className="button-download">
            <svg width="18" height="18">
              <use href={sprite + "#icon-download"}></use>
            </svg>
            Download all files
          </Button>
        </div> */}
      </div>
    </div>
  );
};
