import React from "react";

import "./style.less";

type Props = {
  columnsList: any[];
  tableColumns: any;
  item: any;
  className?: string;
};

export const Row = ({
  columnsList,
  tableColumns,
  item,
  className
}: Props) => {
  return (
    <div
      className={`figma-row mx-0 design-component-instance-node-2 ${className}`}
      onClick={() => {}}
    >
      {columnsList.map( (column, i) => {
        return <div className={`frame ${i === 0 ? 'column-with-right-border' : ''} ${column.containerClass || ''}`} key={i}>
        {typeof tableColumns[column.columnName] === 'function' ? tableColumns[column.columnName](item) : tableColumns[column.columnName]}
      </div>
      })}
    </div>
  );
};
