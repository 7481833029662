import * as React from "react";
import Button from '@mui/material/Button';

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { ModalLayout } from "../ModalLayout/ModalLayout";

import "./style.css";

export const PendingModal = ({ open, onClose, confirm }) => {
  return (
    <ModalLayout open={open} onClose={onClose} title={"Reject Move To Pending"}>
      <div className="pending-modal">
        <p className="pending-p">
          You have chosen to consider this applicant employment and to move them
          into the Pending Employment status. Please confirm this action.
        </p>
        <div className="pending-not mb-4">
          <div>
            <svg style={{ stroke: "#63A6F7" }} width="20" height="20">
              <use href={sprite + "#icon-info"} />
            </svg>
          </div>
          <div>
            Note: Once the driver is moved to pending status, you have 45 days
            to hire the driver or they will be moved to inactive status and the
            driver will need to re-apply.
          </div>
        </div>
        <div className="modal-buttons">
          <Button variant="outlined" className="button-modal" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" className="button-modal" onClick={confirm}>
            Confirm
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
