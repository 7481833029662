
import React from "react";

import { FilterTabItem } from "../FilterTabItem";

import "./style.less";

export const SegmentedButton = ({
  btns,
  selectTab
}) => {
  return (
    <div className={`segmented-button`}>
      {btns.map((tab, i) => {
        return <FilterTabItem
          key={i}
          click={() => selectTab && selectTab(tab.id)}
          isActive={tab.isActive}
          text={tab.text}
        />
      })}
    </div>
  );
};
