import { CSSProperties } from 'react';
import Select, { StylesConfig, ControlProps, OptionProps } from "react-select";

export type MyOptionType = {
	label: string;
	value: string | number;
};
type IsMulti = false;

// for styles options in react-select
// @ts-ignore
export const selectCustomStylesFun: () => StylesConfig<MyOptionType, IsMulti> = (styles?) => {
  const control = styles ? {...styles.control} : {};
  const valueContainer = styles ? {...styles.valueContainer} : {};
	return {
		// for more information look at node_modules\@types\react-select\src\styles.d.ts
		control: (base, state) => {
			return {
				...base,
        		...control,
				width: '100%',
        		background: "transparent",
				border: "1px solid var(--m-3syslightoutline)",
				borderRadius: "4px"
			};
		},
		valueContainer: (provided, state) => ({
			...provided,
			...valueContainer,
			fontFamily: "Roboto, Helvetica",
			cursor: 'text',
			"&:hover": {
				cursor: 'text',
			}
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			cursor: 'pointer',
			transition: 'all .2s ease',
    		transform: state.selectProps.menuIsOpen ? 'scale(1, -1)' : null,
			"&:hover": {
				cursor: 'pointer',
			}
		}),
		menu: (base, state) => {
			return {
				...base,
				zIndex: 100,
				fontFamily: "Roboto, Helvetica",
				marginTop: 0,
				borderRadius: '4px',
				boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
				"&:hover": {
					borderRadius: '4px',
					boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)",
				}
			};
		},
		option: (base, state) => {
			return {
				...base,
				lineHeight: "40px",
				height: "56px",
			};
		},
		input: (_styles) => ({
    		..._styles
		})
	};
};
