import React from "react";
import { Provider } from 'react-redux';
import ReactDOMClient from "react-dom/client";

import './_styles/index.less';
import { store } from './_redux/Store.js';
import { App } from './App';

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app); // Створюємо корінь

// Викликаємо render з одним аргументом
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

