/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Logo = ({ className }) => {
  return (
    <svg
      className={`logo ${className}`}
      fill="none"
      height="41"
      viewBox="0 0 110 41"
      width="110"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M4.03041 24.9313C4.4182 24.6152 4.80599 24.2976 5.18535 23.9731C5.47198 23.7272 5.80076 23.3956 6.16325 23.0134C5.97498 22.0482 5.87382 21.0506 5.87382 20.0305C5.87382 11.4387 12.8386 4.47398 21.4304 4.47398C30.0222 4.47398 36.9869 11.4387 36.9869 20.0305C36.9869 21.0506 36.8858 22.0482 36.6975 23.0134C37.06 23.3942 37.3888 23.7272 37.6754 23.9731C38.0548 24.2976 38.4426 24.6152 38.829 24.9313C39.3558 25.3613 39.8982 25.8067 40.4293 26.2816C41.0756 24.3145 41.4311 22.2154 41.4311 20.032C41.4311 8.98555 32.4768 0.03125 21.4304 0.03125C10.384 0.03125 1.42969 8.98555 1.42969 20.032C1.42969 22.2154 1.78376 24.3145 2.43148 26.2816C2.96258 25.8067 3.50493 25.3627 4.03182 24.9327L4.03041 24.9313Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M42.8536 32.5069C42.1399 28.9353 39.282 27.1509 36.7825 25.0082C34.2829 22.8655 30.3544 16.4375 21.4268 16.4375C12.4992 16.4375 8.57073 22.8655 6.07117 25.0082C3.5716 27.1509 0.715164 28.9367 0 32.5069C0 32.5069 2.41666 31.8494 4.65208 30.9164C8.21947 36.3989 14.396 40.0281 21.4254 40.0281C28.4548 40.0281 34.6313 36.3989 38.1987 30.9164C40.4342 31.8494 42.8508 32.5055 42.8508 32.5055L42.8536 32.5069ZM21.4282 35.5839C15.9078 35.5839 11.0619 32.705 8.30096 28.3705C8.30096 28.3705 8.29955 28.3719 8.29815 28.3733C10.2638 25.6208 13.1596 20.0091 21.4282 20.0091C29.6969 20.0091 32.5898 25.618 34.5555 28.3705C31.7946 32.7064 26.9486 35.5839 21.4282 35.5839Z"
        fill="url(#paint0_linear_3596_179510)"
      />
      <path
        className="path"
        d="M87.2286 4.67188V17.0514H84.9633V6.93721C84.9633 5.6872 85.9786 4.67188 87.2286 4.67188Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M88.8257 8.21655H91.0389V13.1791C91.0389 14.5106 91.9891 14.9946 92.6817 14.9946C93.3743 14.9946 94.4793 14.3118 94.4793 13.2655V8.1481H96.7088V17.0514H96.0862L95.3251 15.979C95.3251 15.979 94.375 17.1899 92.6801 17.1899C90.9851 17.1899 88.8078 15.979 88.8078 13.0406C88.8078 10.1022 88.8078 8.23448 88.8257 8.21655Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M105.622 8.21655V10.4118H100.886C100.886 10.4118 100.35 10.4558 100.35 11.0083C100.35 11.5608 100.886 11.6227 100.886 11.6227H103.617C103.617 11.6227 106.055 11.8655 106.055 14.1814C106.055 16.4972 103.515 17.0514 103.515 17.0514H98.1723V14.8382H103.321C103.321 14.8382 103.87 14.817 103.87 14.3199C103.87 13.8228 103.321 13.8017 103.321 13.8017H100.68C100.68 13.8017 98.156 13.4904 98.156 11.0523C98.156 8.61421 101.06 8.18233 101.06 8.18233C101.06 8.18233 105.597 8.24263 105.623 8.21655H105.622Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M58.9478 5.08746C55.5677 5.08746 52.8281 7.82705 52.8281 11.2071C52.8281 14.5872 55.5677 17.3268 58.9478 17.3268C62.3279 17.3268 65.0674 14.5872 65.0674 11.2071C65.0674 7.82705 62.3279 5.08746 58.9478 5.08746ZM58.9478 15.0289C56.8373 15.0289 55.1277 13.3176 55.1277 11.2088C55.1277 9.09987 56.8389 7.38865 58.9478 7.38865C61.0567 7.38865 62.7679 9.09987 62.7679 11.2088C62.7679 13.3176 61.0567 15.0289 58.9478 15.0289Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M72.5724 13.7511C72.1519 14.4666 71.3762 14.949 70.4863 14.949C69.1499 14.949 68.0662 13.8652 68.0662 12.5288C68.0662 11.1925 69.1499 10.1087 70.4863 10.1087C71.399 10.1087 72.1927 10.6139 72.605 11.3603L74.2152 9.75014C73.3677 8.61421 72.0118 7.87757 70.4863 7.87757C67.9179 7.87757 65.8351 9.96037 65.8351 12.5288C65.8351 15.0973 67.9179 17.1801 70.4863 17.1801C71.9906 17.1801 73.3286 16.4646 74.1777 15.3564L72.5724 13.7511Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M80.193 22.2633C81.0193 22.2633 81.6891 21.5934 81.6891 20.7672C81.6891 19.9409 81.0193 19.2711 80.193 19.2711C79.3668 19.2711 78.6969 19.9409 78.6969 20.7672C78.6969 21.5934 79.3668 22.2633 80.193 22.2633Z"
        fill="#031933"
      />
      <path className="path" d="M81.3273 23.1873H79.062V32.0563H81.3273V23.1873Z" fill="#031933" />
      <path
        className="path"
        d="M56.3614 20.231H62.7027V22.599H56.8585V25.0191H60.351V27.2845H56.8585V32.0563H54.4905L54.5052 22.084C54.5052 21.0605 55.3363 20.231 56.3614 20.231Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M63.1069 23.2395H63.6593L64.4547 24.3281C64.4547 24.3281 65.1815 23.2395 66.8585 23.2395H69.0016V25.4869H66.7721C66.7721 25.4869 65.3021 25.6775 65.3021 27.1296V32.0058H63.0889C63.0889 32.0058 63.1069 23.2232 63.1069 23.2411V23.2395Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M95.5924 19.6785V23.655C95.5924 23.655 96.1449 23.0504 97.5628 23.0504C99.4728 23.0504 101.262 24.9181 101.262 26.8363V32.0058H98.997V27.4246C98.997 27.4246 99.1485 25.2978 97.3852 25.2978C95.6218 25.2978 95.5908 27.1133 95.5908 27.1133V31.9895H93.4119L93.3841 21.8933C93.3809 20.6726 94.3701 19.6801 95.5908 19.6801L95.5924 19.6785Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M105.641 19.6785V23.2916H109.03V25.4347H105.641V28.1662C105.641 28.1662 105.884 29.8089 107.3 29.8089H109.03V32.0221H107.266C107.266 32.0221 103.462 31.5723 103.462 28.0798C103.462 24.5873 103.462 25.4526 103.462 25.4526H102.39V23.2737H103.497V21.8216C103.497 20.6384 104.457 19.6785 105.64 19.6785H105.641Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M73.9577 30.0648C73.7686 30.0648 73.5861 30.042 73.4101 30.0012L77.7387 24.7682C76.8945 23.5915 75.5173 22.8255 73.9577 22.8255C71.3892 22.8255 69.3064 24.9083 69.3064 27.4768C69.3064 30.0452 71.3892 32.128 73.9577 32.128C75.4619 32.128 76.7999 31.4126 77.649 30.3044L76.1236 28.7789C75.7048 29.5449 74.8915 30.0648 73.956 30.0648H73.9577ZM73.9577 25.1283C74.2103 25.1283 74.4531 25.1658 74.6829 25.2375L71.8569 28.8865C71.6255 28.5117 71.4903 28.07 71.4903 27.5974C71.4903 26.2349 72.5952 25.13 73.9577 25.13V25.1283Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M91.1824 23.2916L90.3316 24.2727C89.4923 23.453 88.3466 22.9461 87.0803 22.9461C84.5118 22.9461 82.429 25.0289 82.429 27.5974C82.429 30.1658 84.5118 32.2486 87.0803 32.2486C87.9213 32.2486 88.71 32.0237 89.3913 31.6326C89.0246 33.1743 87.5692 33.1825 87.5692 33.1825H84.6455V35.412H87.3606C91.492 35.412 91.7348 30.9514 91.7348 30.9514V23.2916H91.1824ZM87.0803 29.9475C85.7814 29.9475 84.7286 28.8946 84.7286 27.5957C84.7286 26.2968 85.7814 25.244 87.0803 25.244C88.3792 25.244 89.432 26.2968 89.432 27.5957C89.432 28.8946 88.3792 29.9475 87.0803 29.9475Z"
        fill="#031933"
      />
      <path
        className="path"
        d="M75.4671 8.21655H77.6803V13.1791C77.6803 14.5106 78.6305 14.9946 79.3231 14.9946C80.0157 14.9946 81.1207 14.3118 81.1207 13.2655V8.1481H83.3502V17.0514H82.7276L81.9665 15.979C81.9665 15.979 81.0164 17.1899 79.3215 17.1899C77.6265 17.1899 75.4492 15.979 75.4492 13.0406C75.4492 10.1022 75.4492 8.23448 75.4671 8.21655Z"
        fill="#031933"
      />
      <defs className="defs">
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3596_179510"
          x1="21.4268"
          x2="21.4268"
          y1="16.4375"
          y2="40.0281"
        >
          <stop className="stop" stopColor="#CC721F" />
          <stop className="stop" offset="1" stopColor="#FFED4D" />
        </linearGradient>
      </defs>
    </svg>
  );
};
