
import React from "react";

import "./style.less";

export const FilterTabItem = ({ isActive = false, text = "?", click }) => {
  return (
    <div className={`filter-tab-item ${isActive && 'active-tab'} `} onClick={() => click && click()}>
      <div className={`text-main-13 font-w-600 ${isActive && 'color-lighton'}`}>{text}</div>
    </div>
  );
};
