import React, { useState, useEffect } from "react";

import { IconCheckSmall4 } from "../../icons/IconCheckSmall4";
import { PASSWORD_LENGTH_MIN } from '../../_redux/_constants';

import "./style.less";

type Props = {
  password: string,
  confirmPassword: string,
  attemptedSubmit: boolean,
  setCheckingRule: (params: any) => void,
}

export const PasswordRules = (props: Props) => {
  const {
    password,
    confirmPassword,
    attemptedSubmit,
    setCheckingRule
  } = props;

  const [validationState, setValidationState] = useState({
    isValidLength: false,
    hasUpperAndLowercase: false,
    hasNumber: false,
    isValidConfirm: false,
  });
  
  useEffect(() => {
    const newIsValidLength = password.length >= PASSWORD_LENGTH_MIN && password.length <= 32;
    const newHasUpperAndLowercase = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const newHasNumber = /\d/.test(password);
    const newIsValidConfirm = password === confirmPassword;

    const newValidCheck = {
      isValidLength: newIsValidLength,
      hasUpperAndLowercase: newHasUpperAndLowercase,
      hasNumber: newHasNumber,
      isValidConfirm: newIsValidConfirm,
    };
    setValidationState(newValidCheck);
    setCheckingRule(newValidCheck);
}, [password, confirmPassword]);

  return (
    <div className="password-rules">
      <div className="password-subtitle">Password Must Include:</div>
      <div className="rules">
        <div className={`rule-item ${!attemptedSubmit || validationState.isValidLength ? "valid" : "invalid"}`}>
          <div className="icon-check-small-wrapper">
            <IconCheckSmall4 className="icon-check-small-4" color={validationState.isValidLength ? "#000" : (attemptedSubmit ? "#D32F2F" : "#CCCCCC")} />
          </div>
          <div className="rule-text">8-32 symbols</div>
        </div>
        <div className={`rule-item ${!attemptedSubmit || validationState.hasUpperAndLowercase ? "valid" : "invalid"}`}>
          <div className="icon-check-small-wrapper">
            <IconCheckSmall4 className="icon-check-small-4" color={validationState.hasUpperAndLowercase ? "#000" : (attemptedSubmit ? "#D32F2F" : "#CCCCCC")} />
          </div>
          <p className="rule-text">At least one upper and lowercase character</p>
        </div>
        <div className={`rule-item ${!attemptedSubmit || validationState.hasNumber ? "valid" : "invalid"}`}>
          <div className="icon-check-small-wrapper">
            <IconCheckSmall4 className="icon-check-small-4" color={validationState.hasNumber ? "#000" : (attemptedSubmit ? "#D32F2F" : "#CCCCCC")} />
          </div>
          <div className="rule-text">At least one number</div>
        </div>
      </div>
    </div>
  );
};
