import React, { useEffect } from "react";
import "./style.less";

export const TableFooter = ({ listLength }) => {
  return (
    <div className="pages design-component-instance-node-2">
      <div className="frame-3">
        <div className="text-wrapper-4">Rows per page: {listLength}</div>
      </div>
    </div>
  );
};
