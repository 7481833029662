import React, { useState, useMemo } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";

import { QualificationTab } from "../QualificationTab/QualificationTab";
import { FileTab } from "../FileTab/FileTab";
import { DriverInfoBlock } from "../DriverInfoBlock/DriverInfoBlock";
import { ShortInfoItem } from "../ShortInfoItem/ShortInfoItem";
import { IconButtonSmall } from "../../icons/IconButtonSmall";
import { IconCopy } from "../../icons/IconCopy";
import { IconVisibility15 } from "../../icons/IconVisibility15";
import {
  STORE,
  Application,
  DeniedLicense,
  Dictionary,
  ResidencesPrevious,
  EmploymentRecord,
  Experience,
  AccidentsCrashes
} from "../../_redux/types";
import { dateEdit } from "../../js/helpers";

import "./style.css";
import { handleCopy } from "../../helpers/clipboardHelper";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";

const tabStyle = {
  fontFamily: "Roboto",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "16px" /* 133.333% */,
  letterSpacing: "0.5px",
  textTransform: "uppercase",
  padding: "0",
  minWidth: "40px",
  marginRight: "24px",
};

export const ApplicantsTab = () => {
  let { applicantId } = useParams();
  const {applications} = useSelector((state: STORE) => state.appData);
  const countries = useSelector((state: STORE) => state.appData.dictionaries.countries);
  const states = useSelector((state: STORE) => state.appData.dictionaries.states);
  const driver_license_classes = useSelector((state: STORE) => state.appData.dictionaries.driver_license_classes);
  const endorsements = useSelector((state: STORE) => state.appData.dictionaries.endorsements);
  const vehicle_types = useSelector((state: STORE) => state.appData.dictionaries.vehicle_types);

  const [tab, setTab] = useState("1");

  const application: Application = useMemo(() => {
    const application = applicantId ? applications.find( (appl) => appl.id == Number(applicantId?.slice(1))) : {};
    return application as Application;
  }, [applicantId, applications]);

  const driverse_licence_expires: string = useMemo(() => {
    let timeString = "_____";
    if(application?.driver_licenses[0] && application.driver_licenses[0]?.expires) {
      const currentDateTime = Number(new Date(application.driver_licenses[0]?.expires));
      timeString = dateEdit(currentDateTime, false) as string;
    }
    return timeString;
  }, [application]);

  const create_time: string = useMemo(() => {
    const currentDateTime = Number(new Date(application?.create_time));
    const timeString = dateEdit(currentDateTime, false) as string;
    return timeString;
  }, [application]);

  const fullName: string = useMemo(() => {
    const fullName = application?.driver_first_name || application?.driver_middle_name || application?.driver_last_name
      ? application?.driver_first_name + ' ' + application?.driver_middle_name + ' ' + application?.driver_last_name
      : "Empty"
    return fullName;
  }, [application]);

  const driver_date_of_birth: string = useMemo(() => {
    let date_of_birth = application?.driver_date_of_birth || "Empty"
    if(application?.driver_date_of_birth) {
      const time = Number(new Date(application?.driver_date_of_birth));
      date_of_birth = dateEdit(time, false) as string;
    }
    return date_of_birth;
  }, [application]);

  const medical_card_date: string = useMemo(() => {
    let medical_card_date_ = application?.medical_card_date || "Empty"
    if(application?.medical_card_date) {
      const time = Number(new Date(application?.medical_card_date));
      medical_card_date_ = dateEdit(time, false) as string;
    }
    return medical_card_date_;
  }, [application]);

  const driver_ssn: string = useMemo(() => {
    return application?.driver_ssn;
  }, [application]);

  const getBoolVal = (value) => {
    return value == null ? "It is unknown" : value ? "Yes" : "No";
  };

  const getRadioCheckVal = (value) => {
    return value == null ? "It is unknown" : value;
  };

  const getState = (id) => {
    const stateData_ = id ? (states?.find( (st) => st.dict_id == id ) || {} as Dictionary) : {} as Dictionary;
    return stateData_?.lang_en || "";
  };

  const getCountry = (id) => {
    const stateData_ = id ? (countries?.find( (st) => st.dict_id == id ) || {} as Dictionary) : {} as Dictionary;
    return stateData_?.lang_en || "";
  };

  const getDriverLicenseClass = (id) => {
    const stateData_ = id ? (driver_license_classes?.find( (st) => st.dict_id == id ) || {} as Dictionary) : {} as Dictionary;
    return stateData_?.lang_en || "";
  };

  const getVehicleTypes = (id) => {
    const stateData_ = id ? (vehicle_types?.find( (st) => st.dict_id == id ) || {} as Dictionary) : {} as Dictionary;
    return stateData_?.lang_en || "";
  };

  const getEndorsements = (ids) => {
    let  endorsements_ = "";
    ids?.length && endorsements?.forEach( (st) => {
      endorsements_ = ids.includes(st.dict_id) ? (!endorsements_ ? st.lang_en : endorsements_ + ', ' + st.lang_en) : endorsements_;
    });
    return endorsements_;
  };

  const address: string = useMemo(() => {
    return application?.address || "Empty"
  }, [application]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const fullAddress: string = useMemo(() => {
    const street = application?.address || "No street address";
    const city = application?.city || "No city";
    const state = getState(application?.state); 
    const country = getCountry(application?.country); 
    const zipCode = application?.zip || "No ZIP code";
  
    // Повна адресса
    return `${street}, ${city}, ${state}, ${country}, ${zipCode}`;

  }, [application]);

  const medicalCertificate = [ 
    {
      subtitle: "",
      subrows: [
        {
          field_name: "Expiration Date",
          field_value: <div className="color-primary">{medical_card_date}</div>,
          field_el: null,
          field_class: "",
        },  
      ]
    }
  ]

  const residencesPrevious3Years = useMemo(() => {
    if (!application?.residences_previous) return [];
  
    return application.residences_previous.map((residences_prev: ResidencesPrevious, index: number) => {
      const street = residences_prev?.address || "No street address";
      const city = residences_prev?.city || "No city";
      const state = getState(residences_prev?.state);
      const country = getCountry(residences_prev?.country);
      const zipCode = residences_prev?.zip || "No ZIP code";
     // Повна адресса
      const fullAddress = `${street}, ${city}, ${state}, ${country}, ${zipCode}`;
      return {
        subtitle: "RESIDENSE #" + (index + 1),
        subrows: [
          {
            field_name: "Address",
            field_value: fullAddress,
            field_el: null,
            field_class: "",
          }
        ]
      };
    });
  }, [application?.residences_previous, states, countries]);

  const driversLicense = useMemo(() => !application?.driver_licenses ? []
    : application?.driver_licenses.map((license: DeniedLicense, index: number) => {

      let issued = license?.issued || "Empty"
      if(license?.issued) {
        const time = Number(new Date(license?.issued));
        issued = dateEdit(time, false) as string;
      }
      let expires = license?.expires || "Empty"
      if(license?.expires) {
        const time = Number(new Date(license?.expires));
        expires = dateEdit(time, false) as string;
      }
      const state_country = getState(license?.state) + ' ' + getCountry(license?.country); // todo

      return {
        subtitle: application?.driver_licenses.length > 1 ? index+1 : "",
        subrows: [
          {
            field_name: "License Numder",
            field_value: license?.license_number || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Full Name",
            field_value: license?.first_name || license?.last_name
              ? license?.first_name + ' ' + license?.last_name
              : "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Issued",
            field_value: issued,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Expires",
            field_value: <div className="style-data">{expires}</div>,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "State, Country",
            field_value: state_country,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Class",
            field_value: getDriverLicenseClass(license?.driver_license_class),
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Endorsements",
            field_value: getEndorsements(license?.endorsements?.split(', ').map(id => +id)),
            field_el: null,
            field_class: "",
          },
        ]
      }
    }
  ), [application?.driver_licenses]);

  const employment_record = useMemo(() => {
    if (!application?.employment_record) return [];
  
    return application.employment_record.map((employment_rec: EmploymentRecord, index: number) => {
      let date_from = employment_rec?.date_from || "Empty";
      if (employment_rec?.date_from) {
        const time = Number(new Date(employment_rec?.date_from));
        date_from = dateEdit(time, false) as string;
      }
      let date_to = employment_rec?.date_to || "Empty";
      if (employment_rec?.date_to) {
        const time = Number(new Date(employment_rec?.date_to));
        date_to = dateEdit(time, false) as string;
      }
  
      const street = employment_rec?.address || "No street address";
      const city = employment_rec?.city || "No city";
      const state = getState(employment_rec?.state);
      const country = getCountry(employment_rec?.country);
      const zipCode = employment_rec?.zip || "No ZIP code";
      // Повна адреса
      const fullAddress = `${street}, ${city}, ${state}, ${country}, ${zipCode}`;
  
      return {
        subtitle: employment_rec?.employer_name,
        subrows: [
          {
            field_name: "Address",
            field_value: fullAddress || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Phone",
            field_value: employment_rec?.phone || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Position Held",
            field_value: employment_rec?.position_held,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "From - To",
            field_value: date_from + ' - ' + date_to,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Reason For Leaving",
            field_value: <div className="style-data">{employment_rec?.reason_for_leaving}</div>,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Were you subject to the DOT/FMCSA regulations while employed by this carrier?",
            field_value: <div className="">{getRadioCheckVal(employment_rec?.subject_dot_fmcsa)}</div>,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Was your job designated as a safety-sensitive function subject to DOT alcohol and drug testing under 49 CFR Part 40?",
            field_value: <div className="">{getRadioCheckVal(employment_rec?.cfr_part_40)}</div>,
            field_el: null,
            field_class: "",
          },
        ]
      }
    });
  }, [application?.employment_record, states, countries]);
  
  const experience = useMemo(() => ([{
    subtitle: "",
    subrows: !application?.experience ? [] : application?.experience.map((exper: Experience, index: number) => {
      const time1 = Number(new Date(exper?.date_from));
      const date_from = dateEdit(time1, false) as string;
      const time2 = Number(new Date(exper?.date_to));
      const date_to = dateEdit(time2, false) as string;
      const approx_miles = exper?.approx_miles ? (exper?.approx_miles+'').replace(/(.{3})/g, '$1 ') : exper?.approx_miles === 0 ? '0' :'';
      return {
        field_name: getVehicleTypes(exper?.vehicle_type),
        field_value: date_from + ' - ' + date_to + ', ' + approx_miles.trim() + ' miles',
        field_el: null,
        field_class: "",
      };
    })
  }]), [application?.experience]);

  const accidents_crashes = useMemo(() => !application?.accidents_crashes ? []
    : application?.accidents_crashes.map((accident_crash: AccidentsCrashes, index: number) => {

      let date = accident_crash?.date || "Empty"
      if(accident_crash?.date) {
        const time = Number(new Date(accident_crash?.date));
        date = dateEdit(time, false) as string;
      }

      return {
        subtitle: 'Accident/Crash #'+(index+1),
        subrows: [
          {
            field_name: "Date",
            field_value: date,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Location City/State",
            field_value: accident_crash?.location_city_state || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Number of Injuries",
            field_value: accident_crash?.number_of_injuries || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Number of Fatalities",
            field_value: accident_crash?.number_of_fatalities || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Hazmat Spill?",
            field_value: accident_crash?.hazmat_spill === 0 ? "No" : accident_crash?.hazmat_spill === 1 ? "Yes" : "Empty",
            field_el: null,
            field_class: "",
          },
        ]
      }
    }
  ), [application?.accidents_crashes]);

  const traffic_violations = useMemo(() => !application?.traffic_violations ? []
    : application?.traffic_violations.map((traffic_violation: any, index: number) => {

      let date = traffic_violation?.date || "Empty"
      if(traffic_violation?.date) {
        const time = Number(new Date(traffic_violation?.date));
        date = dateEdit(time, false) as string;
      }

      return {
        subtitle: 'Moving Traffic Violation #'+(index+1),
        subrows: [
          {
            field_name: "Date",
            field_value: date,
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Location City/State",
            field_value: traffic_violation?.location_city_state || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Offense",
            field_value: traffic_violation?.offense || "Empty",
            field_el: null,
            field_class: "",
          },
          {
            field_name: "Vehicle type",
            field_value: traffic_violation?.vehicle_type ? getEndorsements([traffic_violation?.vehicle_type]) : "Empty",
            field_el: null,
            field_class: "",
          },
        ]
      }
    }
  ), [application?.traffic_violations]);

  const contactInformation = [
    {
      subtitle: "",
      subrows: [
        {
          field_name: "Phone",
          field_value: "",// додати значок номеру 
          field_el: <div className="row mx-0 align-items-center">
            {application?.phone_number && <IconButtonSmall
              classes={"col"}
              configuration="grey"
              icon={<IconCopy className="icon-copy cursor-pointer" color="#6B6D82" />}
              stateProp="enabled"
              tooltipClassName="icon-button-small-instance"
              tooltipText="Copy"
              onClick={() => handleCopy(formatPhoneNumber(application?.phone_number || ''))}
            />}
            <div className="col-auto text-main-13 font-w-600 color-primary px-0">{formatPhoneNumber(application?.phone_number || '')}</div>
          </div>,
        // <div className="style-data-1">+1(000) 000-0000</div>, 
          field_class: "",
        },
        {
          field_name: "Email",
          field_value: "", //додати значок емайл
          field_el: <div className={"row mx-0 align-items-center"}>
            {application?.email && <IconButtonSmall
              classes={"col"}
              configuration="grey"
              icon={<IconCopy className="icon-copy cursor-pointer" color="#6B6D82" />}
              stateProp="enabled"
              tooltipClassName="icon-button-small-instance"
              tooltipText="Copy"
              onClick={() => handleCopy(application.email)}
            />}
            <div className="col-auto text-main-13 font-w-600 color-primary px-0">{application?.email}</div>
          </div>,
        // <div className="style-data-1">mymail@yahoo.com</div>,
          field_class: "",
        },
        {
          field_name: "Adress",
          field_value: fullAddress,
          field_el: null,
          field_class: "",
        },
      ]
    },
  ];

  const generalItems = [ 
    {
      subtitle: "",
      subrows: [
        {
          field_name: "Full Name",
          field_value: fullName,
          field_el: null,
          field_class: "",
        },
        {
          field_name: "Data of Birth",
          field_value: driver_date_of_birth,
          field_el: null,
          field_class: "",
        },
        {
          field_name: "SSN",
          field_value: "",
          field_el: <div className="col-8">
            {/* <IconVisibility15 className="mr-2" />{driver_ssn} */}
            {driver_ssn}
          </div>,
          field_class: "",
         },
        {
          field_name: "TWIC Card",
          field_value: getBoolVal(application?.driver_have_twic),
          field_el: null,
          field_class: "",
        },
        {
          field_name: "Passport",
          field_value: getBoolVal(application?.driver_have_passport),
          field_el: null,
          field_class: "",
        },
      ],
    },
  ];

  const forfeitures_previous_3_year = [ 
    {
      subtitle: "",
      subrows: [
        {
          field_name: "Have you ever been denied a license, permit or privilege to operate a motor vehicle?",
          field_value: getRadioCheckVal(application?.denied_license),
          field_el: null,
          field_class: "",
        },
        {
          field_name: "Has any license, permit o privilage ever been suspended or revoked?",
          field_value: getRadioCheckVal(application?.suspended_or_revoked),
          field_el: null,
          field_class: "",
        },
        {
          field_name: "Describe",
          field_value: application?.forfeitures_short_describe || '',
          field_el: null,
          field_class: "",
        },
      ],
    },
  ];
  
  return (
    <div className="header-tabs">
      <Box sx={{ width: "100%" }} className="h-100">
        <TabContext value={tab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              paddingLeft: "24px",
            }}
          >
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Overview" value="1" sx={tabStyle} />
              <Tab
                label="Qualification Checklist"
                value="2"
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "16px" /* 133.333% */,
                  letterSpacing: "0.5px",
                  textTransform: "uppercase",
                  padding: "0",
                  minWidth: "40px",
                  marginRight: "24px",
                }}
              />
              <Tab label="Files" value="3" sx={tabStyle} />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ padding: "0", overflowY: 'auto' }} className={"p-4 tab-body"}>
            <div className="">
              <div className="text-main-24-32">Applicant Information</div>
              <div className="row py-4">
                <div className="col-3 mx-0">
                  <ShortInfoItem title={"Applied date"} subtitle={create_time}/>
                </div>
                <div className="col-3 mx-0">
                  <ShortInfoItem/>
                </div>
                <div className="col-3 mx-0">
                  <ShortInfoItem title={"Driverse Licence Expires"} subtitle={driverse_licence_expires}/>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <DriverInfoBlock blockTitle={"General"} items={generalItems} />
                  <DriverInfoBlock blockTitle={"Residences previous 3 Years"} items={residencesPrevious3Years} />
                  <DriverInfoBlock blockTitle={"Medical Certificate"} items={medicalCertificate} />
                  <DriverInfoBlock blockTitle={"Experience"} items={experience} />
                  <DriverInfoBlock blockTitle={"Accidents/Crashes Previous 3 Years"} items={accidents_crashes} />
                  <DriverInfoBlock blockTitle={"Moving Traffic Violations Previous 3 Years"} items={traffic_violations} />
                  <DriverInfoBlock blockTitle={"Forfeitures Previous 3 Years"} items={forfeitures_previous_3_year} />
                </div>
                <div className="col-6">
                  <DriverInfoBlock blockTitle={"Contact information"} items={contactInformation} />
                  <DriverInfoBlock blockTitle={"Drivers License"} items={driversLicense} />
                  <DriverInfoBlock blockTitle={"Employment Record Previous 3 Years"} items={employment_record} />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: "0" }} className={"pt-4 px-2 tab-body"}>
            <QualificationTab applic={application}/>
          </TabPanel>
          <TabPanel value="3" sx={{ padding: "0" }} className={"tab-body"}>
            <FileTab  applic={application}/>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};
