import { createStore, applyMiddleware } from 'redux';
import { createLogger, logger } from 'redux-logger';
import {thunk} from 'redux-thunk';
import { configureStore, Tuple } from '@reduxjs/toolkit'

import reducer from './_reducers';
import { allConstants } from './_constants/all_constants.constants';

const loggerMiddleware = createLogger({
	predicate: (_, action) => true
});

// const enhancer = process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : applyMiddleware(thunk , loggerMiddleware);

// const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), enhancer);

const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => process.env.NODE_ENV === 'production' ? getDefaultMiddleware()
		: getDefaultMiddleware().concat(loggerMiddleware)
  })

// dev only
window.store = store;

export { store };