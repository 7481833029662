
// функції для форматування часу.
// Приводить до строки формата "29.03.2017 15:45:03"
export function dateTime(timestamp: number, withHour = true): string {
	var date: Date = new Date(timestamp);
	var year: any = date.getFullYear();
	var month: any = date.getMonth() + 1;
	month = (month < 10 ? "0" : "") + month;
	var day: any = date.getDate();
	day = (day < 10 ? "0" : "") + day;
	let result: string = day + "." + month + "." + year;
	if(withHour) {
		var hour: any = date.getHours();
		hour = (hour < 10 ? "0" : "") + hour;
		var min: any = date.getMinutes();
		min = (min < 10 ? "0" : "") + min;
		var sec: any = date.getSeconds();
		sec = (sec < 10 ? "0" : "") + sec;
		result += " " + hour + ":" + min + ":" + sec;
	}

	return result;
}


export function dateEdit(timestamp: number, withHour = true): string {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	let month: any = date.getMonth() + 1;
	month = (month < 10 ? "0" : "") + month;
	let day: any = date.getDate();
	day = (day < 10 ? "0" : "") + day;
	let result = `${month}.${day}.${year}`;
	if (withHour) {
	  let hour: any = date.getHours();
	  hour = (hour < 10 ? "0" : "") + hour;
	  let min: any = date.getMinutes();
	  min = (min < 10 ? "0" : "") + min;
	  let sec: any = date.getSeconds();
	  sec = (sec < 10 ? "0" : "") + sec;
	  result += ` ${hour}:${min}:${sec}`;
	}
  
	return result;
  }