import React from "react";

import { ButtonColumns } from "../ButtonColumns";
import { FilterChip } from "../FilterChip";
import { Search } from "../Search";

import "./style.less";

type Props = {
  pageName: string;
  segmentedButton?: any;
  actionsBlock?: any;
  onSearch: any;
  data: any;
  keys: any;
};

export const HeaderApplicantsList = ({
  pageName,
  segmentedButton = null,
  actionsBlock = null,
  onSearch,
  data,
  keys,
}: Props) => {
  return (
    <div className="head w-100">
      <div className="screen-list-header">
        <div className="screen-header-title-container">
          <div className="screen-header-title">{pageName}</div>
          {segmentedButton}
        </div>
        {actionsBlock}
      </div>
      <div className="filters">
        <div className="head-search-container">
          <Search
            placeholder={"Search"}
            onFiltered={onSearch}
            data={data}
            keys={keys}
          />
          {/* <Search placeholder={"Search"} /> */}
          {/* <div className="frame-8">
            <div className="text-gray-13-20">Status:</div>
            <FilterChip
              label="New"
            />
            <FilterChip
              isSelect={true}
              label="Pending"
            />
            <FilterChip
              isSelect={true}
              label="Training"
            />
            <FilterChip
              label="Hiring"
            />
          </div> */}
        </div>
        {/* <ButtonColumns /> */}
      </div>
    </div>
  );
};
