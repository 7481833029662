import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { IconDrivers } from "../../icons/IconDrivers";
import { IconTeam } from "../../icons/IconTeam";
import { IconBurger } from "../../icons/IconBurger";
import { IconSettings } from "../../icons/IconSettings";
import { paths } from "../../_redux/_constants";
import { STORE } from "../../_redux/types";

import "./style.less";

export const NavigationRail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(true);

  const {reg_id, company_information} = useSelector((state: STORE) => state.appData);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const goToApplicantsList = () => {
    navigate("/");
  };

  const goToCompanyWorkersList = () => {
    navigate(`/${paths.COMPANY_WORKERS_LIST}`);
  };

  const goToCompanySettings = () => {
    navigate(`/${paths.COMPANY_SETTINGS}`);
  };

  // Функция для определения, активна ли кнопка
  const isActive = (path) => {
    if (path === "/") {
      return location.pathname === path;
    }
    return location.pathname.includes(path);
  };

  return (
    <div className="navigation-rail">
      <div className="menu-fab">
        <button className="button-burger" onClick={toggleMenu}>
          <IconBurger className="icon-menu" />
        </button>
      </div>
      {isExpanded && (
        <div className="destinations">
          <button
            className={`button-side-bar p-0`}
            onClick={goToApplicantsList}
          >
            <IconDrivers
              className={`menu-icon mb-1`}
              classNameSvg="icon-side-bar"
              color={isActive("/") ? "#3749E5" : "#6B6D82"}
            />
            <span className="mb-1">Drivers</span>
          </button>
          {reg_id == company_information.company_id && <button
            className={`button-side-bar p-0`}
            onClick={goToCompanyWorkersList}
          >
            <IconTeam
              className={`menu-icon mb-1`}
              classNameSvg="icon-side-bar"
              color={
                isActive(`/${paths.COMPANY_WORKERS_LIST}`)
                  ? "#3749E5"
                  : "#6B6D82"
              }
            />
            <span className="mb-1">Team</span>
          </button>}
          <button
            className={`button-side-bar p-0`}
            onClick={goToCompanySettings}
          >
            <IconSettings
              className={`menu-icon mb-1`}
              classNameSvg="icon-side-bar"
              color={
                isActive(`/${paths.COMPANY_SETTINGS}`) ? "#3749E5" : "#6B6D82"
              }
            />
            <span className="mb-1">Settings</span>
          </button>
        </div>
      )}
    </div>
  );
};
