
import React from "react";

export const IconDrivers = ({ color = "#6B6D82", className, classNameSvg }) => {
  return (
    <div className={`${className}`}>
      <svg
        className={`${classNameSvg}`}
        fill="none"
        height="25"
        viewBox="0 0 24 25"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="path"
          clipRule="evenodd"
          d="M3.66678 4.03125H20.3332C21.2498 4.03125 22 4.78147 22 5.69803V19.3645C22 20.2811 21.2498 21.0313 20.3332 21.0313H3.66678C2.75022 21.0313 2 20.2811 2 19.3645V5.69803C2 4.78147 2.75022 4.03125 3.66678 4.03125ZM14.8329 16.6979H19.4998V15.0311H14.8329V16.6979ZM14.8329 13.3643H19.4998V11.6979H14.8329V13.3643ZM13.1668 10.0311H19.4998V8.36436H13.1668V10.0311ZM8.5 7.36426C9.60575 7.36426 10.5001 8.25969 10.5001 9.36436C10.5001 10.469 9.60575 11.3645 8.5 11.3645C7.39447 11.3645 6.50032 10.469 6.50032 9.36436C6.50032 8.25969 7.39447 7.36426 8.5 7.36426ZM12.5002 17.3643H4.5V15.365C4.5 14.2603 5.39645 13.3653 6.50011 13.3653L10.4999 13.3641C11.6054 13.3641 12.5 14.2595 12.5 15.3642L12.5002 17.3643Z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};
