
/**
 * Функція для копіювання тексту з обробкою помилок
 */
export async function handleCopy(text: string) {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      navigator?.clipboard?.writeText(text).then((res) => {
        console.log("🚀 copy ok")
      }).catch(err => {
        console.error('Помилка копіювання: ', err);
      });
    } else {
      // костиль для http
        const textArea = document.createElement("textarea");
        textArea.value = text;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";
            
        document.body.prepend(textArea);
        textArea.select();
  
        try {
          document.execCommand('copy');
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
    }
  } catch (err) {
    console.log("🚀 handleCopy err:", err)
  }
}