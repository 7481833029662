export const formatPhoneNumber = (phoneNumber: string): string => {
    const digits = phoneNumber.replace(/\D/g, ''); 
    const length = digits.length;
  
    if (length === 10) {
      // Якщо 10 цифр, додаємо +1 і форматуємо як +1(XXX)XXX-XXXX  
      return `+1 (${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
    } else if (length > 10) {
      //  Якщо більше 10 цифр, додаємо + і форматуємо як +XXX(XXX)XXX-XXXX
      const countryCodeLength = length - 10;
      return `+${digits.slice(0, countryCodeLength)} (${digits.slice(countryCodeLength, countryCodeLength + 3)}) ${digits.slice(countryCodeLength + 3, countryCodeLength + 6)}-${digits.slice(countryCodeLength + 6)}`;
    } else {
      // Якщо менше 10 цифр, додаємо +1 і заповнюємо цифри, що відсутні, підкресленням в кінці
      const filledNumber = digits.padEnd(10, '_'); // Додаємо символи _ до кінця рядка
      return `+1 (${filledNumber.slice(0, 3)}) ${filledNumber.slice(3, 6)}-${filledNumber.slice(6)}`;
    }
  };
  