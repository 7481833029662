import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from "@mui/material";

// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { StaticDrawer } from "../Drawer/Drawer";
import { DrawerLayout } from "../DrawerLayout/DrawerLayout";
import { FileItem } from "../FileItem";
import { Upload } from "../Upload/Upload";
import { Dispatch, STORE, IObj } from '../../_redux/types';
import { allConstants } from "../../_redux/_constants";
import { allActions } from '../../_redux/_actions';

import "./style.less";

export const FileTab = ({applic = {}}: any ) => {
  const dispatch: Dispatch = useDispatch();
  const {uploadData} = useSelector((state: STORE) => state.appData);

	const set_key = (key1: string, value: string | null | IObj) => {
    dispatch({
      type: allConstants.SET_KEY,
      payload: {
        key1,
        value
      }
    });
	};

  const saveImgToRedux = (imagePreviewUrl, imgData) => {
    const image_data = imagePreviewUrl?.split('base64,')[1];
    const image_type = imagePreviewUrl?.split('base64,')[0].split('/')[1].slice(0, -1);
    const image = { image_type: image_type || imgData.type, image_name: imgData?.name, image_data };
    set_key("uploadData", image);
  }

  const removeImg = () => {
    set_key("uploadData", {});
  }

  async function uploadImg() {
    const res = await dispatch(allActions.uploadImg({...uploadData, owner_type: 'ENTITY_APPLICATION', owner_id: applic?.id }) as any);
    if(res.type === allConstants.ACTION_FAILURE) {
    } else {
      removeImg()
    }
  }

  return (
    <div className="FileTab">
      <div className="d-flex h-100">
        {/* tyt ecran */}
        <div className="screen">
          <p className="text-main-24-32">File Browser</p>
          <div className="file-wrap">
            {applic.documents && applic.documents.map( (doc, i: number) => {
              return <FileItem key={i} title={"Upload File"} data={doc}/>
            })}
          </div>
        </div>
        {/* tyt drawer */}
        <div className="h-100">
          <DrawerLayout title="Upload File" containerClasses={"h-100"}>
            <div className="file-drawer-wrap">
              <Upload 
                value={uploadData}
                saveToRedux={(imagePreviewUrl, imgData) => saveImgToRedux(imagePreviewUrl, imgData)}
                removeImg={() => removeImg()}
                upload={uploadImg}
              />
            </div>
          </DrawerLayout>
        </div>
      </div>
    </div>
  );
};
