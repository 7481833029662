/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconVisibility15 = ({ className }) => {
  return (
    <svg
      className={`icon-visibility-15 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" opacity="0.38">
        <path
          className="path"
          d="M10 5.41667C13.1583 5.41667 15.975 7.19167 17.35 10C15.975 12.8083 13.1583 14.5833 10 14.5833C6.84167 14.5833 4.025 12.8083 2.65 10C4.025 7.19167 6.84167 5.41667 10 5.41667ZM10 3.75C5.83333 3.75 2.275 6.34167 0.833333 10C2.275 13.6583 5.83333 16.25 10 16.25C14.1667 16.25 17.725 13.6583 19.1667 10C17.725 6.34167 14.1667 3.75 10 3.75ZM10 7.91667C11.15 7.91667 12.0833 8.85 12.0833 10C12.0833 11.15 11.15 12.0833 10 12.0833C8.85 12.0833 7.91667 11.15 7.91667 10C7.91667 8.85 8.85 7.91667 10 7.91667ZM10 6.25C7.93333 6.25 6.25 7.93333 6.25 10C6.25 12.0667 7.93333 13.75 10 13.75C12.0667 13.75 13.75 12.0667 13.75 10C13.75 7.93333 12.0667 6.25 10 6.25Z"
          fill="#171F30"
        />
      </g>
    </svg>
  );
};
