import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconPlus } from "../../icons/IconPlus";
import { BtnRegistration } from "../../components/BtnRegistration";
import { HeaderApplicantsList } from "../../components/HeaderApplicantsList";
import { CompanyWorkersTable } from "../../components/CompanyWorkersTable";
import { allConstants } from "../../_redux/_constants";
import { allActions } from "../../_redux/_actions";
import { Dispatch, STORE } from "../../_redux/types";

import "./style.less";

export const CompanyWorkersList = () => {
  const dispatch: Dispatch = useDispatch();
  const { company_id } = useSelector((state: STORE) => state.appData.company_information);
  const workers = useSelector((state: STORE) => state.appData.companyWorkers);
  const [filteredWorkers, setFilteredWorkers] = useState(workers);
  useEffect(() => {
    (async () => {
      const res: any = await allActions.getCompanyWorkers(dispatch, company_id);
    })();
  }, []);

  useEffect(() => {
    setFilteredWorkers(workers);
  }, [workers]);

  useEffect(() => {
    if (workers.length === 0) {
      (async () => {
        await allActions.getCompanyWorkers(dispatch, company_id);
      })();
    }
  }, [dispatch, company_id, workers.length]);

  function openInviteForm() {
    dispatch({
      type: allConstants.SET_MODAL_DATA,
      payload: { open: true },
    });
  }

  return (
    <div className="p-3">
      <div className="body screen-border ">
        <HeaderApplicantsList
          pageName={"Company workers"}
          actionsBlock={
            <div className="right-content w-100">
              <BtnRegistration
                icon={<IconPlus className={"mr-2"} color={"#FFF"} />}
                submitText={"Invite"}
                onClick={() => {
                  openInviteForm();
                }}
                classes={"padding-10-20-10-13"}
              />
            </div>
          }
          onSearch={setFilteredWorkers}
          data={workers}
          keys={["first_name", "last_name", "e_mail", "phone_number"]}
        />
        <CompanyWorkersTable workers={filteredWorkers} />
      </div>
    </div>
  );
};
