
import React from "react";

export const IconArrowDropDown = ({ color = "#6B6D82", className = "" }) => {
  return (
    <svg
      className={`icon-arrow-drop-down-27 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.83203 7.91797L9.9987 12.0846L14.1654 7.91797H5.83203Z"
        fill={color}
      />
    </svg>
  );
};
