export const paths = {
	APPLICANTS: 'applicants',
	COMPANY_WORKERS_LIST: 'company-workers-list',
	COMPANY_SETTINGS: 'company-settings',
};

export const authenticationContants = {
	USER_REGISTRATION_REQUEST: 'USER_REGISTRATION_REQUEST',
	USER_REGISTRATION_FAILURE: 'USER_REGISTRATION_FAILURE',
	USER_REGISTRATION_SUCCESS: 'USER_REGISTRATION_SUCCESS',
	USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
	USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGOUT: 'USER_LOGOUT',
};

export const allConstants = {
	SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
	SET_KEY: 'SET_KEY',
	SET_KEY_VALUE: 'SET_KEY_VALUE',
	SET_KEY_INDEX_VALUE: 'SET_KEY_INDEX_VALUE',
	SET_URL: 'SET_URL',
	SET_MODAL_DATA: 'SET_MODAL_DATA',
	LOAD_USER_DATA_SUCCESS: 'LOAD_USER_DATA_SUCCESS',
	ACTION_REQUEST: 'ACTION_REQUEST',
	ACTION_FAILURE: 'ACTION_FAILURE',
	ACTION_SUCCESS: 'ACTION_SUCCESS',
	LOAD_DICTIONARIES_SUCCESS: 'LOAD_DICTIONARIES_SUCCESS',
	COMPANY_WORKER_CREATE: 'COMPANY_WORKER_CREATE',
	LOAD_COMPANY_WORKERS_SUCCESS: 'LOAD_COMPANY_WORKERS_SUCCESS',
	COMPANY_WORKER_DEACTIVATE: 'COMPANY_WORKER_DEACTIVATE',
	COMPANY_WORKER_ACTIVATE: 'COMPANY_WORKER_ACTIVATE',
	LOAD_APLICATIONS_SUCCESS: 'LOAD_APLICATIONS_SUCCESS',
	SET_APPLICATION_STATUS_SUCCESS: 'SET_APPLICATION_STATUS_SUCCESS',
	SET_ALERT_MODAL: 'SET_ALERT_MODAL',
	CLEAR_ALERT_MODAL: 'CLEAR_ALERT_MODAL',
};

export const PASSWORD_LENGTH_MIN = 8

export const applicant_signature_signed = 'applicant_signature_signed'
export const alcohol_drug_signed = 'alcohol_drug_signed'
export const safety_performance_signed = 'safety_performance_signed'
export const PSP_driver_disclosure_signed = 'PSP_driver_disclosure_signed'
export const drug_alcohol_clearinghouse_consent_signed = 'drug_alcohol_clearinghouse_consent_signed'
export const alcohol_drug_testing_policy_signed = 'alcohol_drug_testing_policy_signed'
export const general_work_policy_signed = 'general_work_policy_signed'
export const fair_credit_reporting_authorization_signed = 'fair_credit_reporting_authorization_signed'
