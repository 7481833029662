import React, { useState } from "react";
import { Button, FormControl } from "@mui/base";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

import { ModalLayout } from "../ModalLayout/ModalLayout";

import "./style.css";

const labelStyles = {
  fontFamily: "Roboto",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
  color: "6b6d82",
};
const radioStyles = {
  fontFamily: "Roboto",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#000",
};
const options = [
  { value: "not_good_fit", label: "Applicant is not a good fit for our company" },
  { value: "failed_pre_employment_drug_test", label: "Applicant failed a pre-employment drug test" },
  { value: "background_check", label: "Items found on the background check" },
  { value: "commercial_driver", label: "Items found on the Commercial Driver" },
  { value: "motor_vehicle_report", label: "Items found on the Motor Vehicle Report (MVR)" },
  {
    value: "pre_employment_screening_program",
    label: "Items found on the Pre-Employment Screening Program (PSP) report",
  },
  { value: "other", label: "Other reason" },
];
export const RejectModal = ({ open, onClose, confirm }) => {

  const [description, setDescription] = useState('');
  const [rejectReason, setRejectReason] = useState<string | null>(null);

  return (
    <ModalLayout open={open} onClose={onClose} title={"Reject Applicants"}>
      <FormControl className="form-reject">
        <FormLabel id="radio-buttons-group-label" sx={labelStyles}>
          Please choose the reason below and provide more details if desired.
          Once finished, please confirm the action to not hire the applicant.
        </FormLabel>
        <div className="radio-group  mb-4">
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            defaultValue="other"
            name="radio-buttons-group"
            sx={radioStyles}
            value={rejectReason}
            onChange={(env) => {
              setRejectReason(env.target.value);
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
          </RadioGroup>
          <textarea
            id="describe"
            name="describe"
            placeholder="Describe the reason"
            className="textarea-reject "
            value={description}
            onChange={(env) => {
              setDescription(env.target.value);
            }}
          ></textarea>
        </div>
        <div className="modal-buttons">
          {/* @ts-ignore */}
          <Button variant="outlined" className="button-modal" onClick={onClose}>
            Cancel
          </Button>
          {/* @ts-ignore */}
          <Button variant="outlined" className="button-modal" onClick={() => {
            confirm(JSON.stringify({
              reject_description: description,
              reject_select_opt: {
                value: rejectReason,
                text: options.find(el => el.value === rejectReason)?.label
              },
            }));
          }}>
            Confirm
          </Button>
        </div>
      </FormControl>
    </ModalLayout>
  );
};

