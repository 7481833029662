import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { Dispatch, STORE } from '../../../_redux/types';
import { allConstants } from '../../../_redux/_constants';
import { userActions } from '../../../_redux/_actions';
import { BtnRegistration } from '../../../components/BtnRegistration';

import "./style.less";

type Props = {
  next: () => void,
}
export const RegAccountInformation = ({
  next,
}: Props) => {
  const dispatch: Dispatch = useDispatch();
  const { company_information, urlData } = useSelector((state: STORE) => state.appData);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (field, value, isNumberField = false) => {
    setSubmitted(false);
    const filteredValue = isNumberField ? value.replace(/[^\d]+/g, '') : value;

    dispatch({
      type: allConstants.SET_KEY_VALUE,
      payload: { key1: "company_information", key2: field, value: filteredValue},
    });
  };

  const handleSubmit = async () => {

    const contactInformationIsCorrect = company_information.First_Name.isCorrect
      && company_information.Last_Name.isCorrect
      && company_information.Phone_Number.isCorrect
      && company_information.Work_E_mail.isCorrect
      && company_information.Legal_Name.isCorrect
      && company_information.Phisical_Adress.isCorrect
      && company_information.MC_Number.isCorrect
      && company_information.USDOT_Number.isCorrect
    const contactInformationIsValid = company_information.First_Name.value
      && company_information.Last_Name.value
      && company_information.Phone_Number.value
      && company_information.Work_E_mail.value
      && company_information.Legal_Name.value
      && company_information.Phisical_Adress.value
      && company_information.MC_Number.value
      && company_information.USDOT_Number.value

    const formIsValid = contactInformationIsCorrect && contactInformationIsValid;

    if(!submitted) {
      setSubmitted(true);
      if (formIsValid) {
        const res: any = await dispatch(userActions.registration(
          company_information.First_Name.value,
          company_information.Last_Name.value,
          company_information.Phone_Number.value,
          company_information.Work_E_mail.value,
          company_information.Legal_Name.value,
          company_information.Phisical_Adress.value,
          company_information.MC_Number.value,
          company_information.USDOT_Number.value,
        )) as any;
        setSubmitted(false);
        if(res?.status === 'ok') {
          next()
        }
      } else {
        console.log("Form has errors or incomplete, cannot proceed.");
      }
    }
  };
  
  return (
    <div className="regAccountInformation">
      <div className="form-item screen-title">
        <div className="title">Account Information</div>
        <p className="screen-subtitle">First, you’ll need to create the account.</p>
      </div>
      <div className="form-item">
        <div className="form-list">
          <div className="form-line">
            <TextField
              error={submitted && !company_information.First_Name.isCorrect}
              helperText={submitted ? company_information.First_Name.helperText : ''}
              required
              fullWidth
              id="First_Name"
              className=""
              label="First Name"
              placeholder=""
              value={company_information['First_Name']?.value || ''}
              onChange={(e) => handleChange("First_Name", e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
            <TextField
              error={submitted && !company_information.Last_Name.isCorrect}
              helperText={submitted ? company_information.Last_Name.helperText : ''}
              required
              fullWidth
              id="Last_Name"
              className=""
              label="Last Name"
              placeholder=""
              value={company_information['Last_Name']?.value || ''}
              onChange={(e) => handleChange("Last_Name", e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
          </div>
          <div className="form-line"> 
            <TextField
              error={submitted && !company_information.Work_E_mail.isCorrect}
              helperText={submitted ? company_information.Work_E_mail.helperText : ''}
              required
              fullWidth
              id="Work_E_mail"
              className=""
              label="Work E-mail"
              placeholder=""
              value={company_information['Work_E_mail']?.value || ''}
              onChange={(e) => handleChange("Work_E_mail", e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
            <TextField
              error={submitted && !company_information.Phone_Number.isCorrect}
              helperText={submitted ? company_information.Phone_Number.helperText : ''}
              required
              fullWidth
              id="Phone_Number"
              className=""
              label="Phone Number"
              placeholder=""
              value={company_information['Phone_Number']?.value || ''}
              onChange={(e) => handleChange("Phone_Number", e.target.value, true)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
          </div>
          <div className="form-line"> 
            <TextField
              error={submitted && !company_information.Legal_Name.isCorrect}
              helperText={submitted ? company_information.Legal_Name.helperText : ''}
              required
              fullWidth
              id="Legal_Name"
              className=""
              label="Company Name"
              placeholder=""
              value={company_information['Legal_Name']?.value || ''}
              onChange={(e) => handleChange("Legal_Name", e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
            <TextField
              error={submitted && !company_information.Phisical_Adress.isCorrect}
              helperText={submitted ? company_information.Phisical_Adress.helperText : ''}
              required
              fullWidth
              id="Phisical_Adress"
              className=""
              label="Phisical Adress"
              placeholder=""
              value={company_information['Phisical_Adress']?.value || ''}
              onChange={(e) => handleChange("Phisical_Adress", e.target.value)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
          </div>
          <div className="form-line"> 
            <TextField
              error={submitted && !company_information.MC_Number.isCorrect}
              helperText={submitted ? company_information.MC_Number.helperText : ''}
              required
              fullWidth
              id="MC_Number"
              className=""
              label="MC Number"
              placeholder=""
              value={company_information['MC_Number']?.value || ''}
              onChange={(e) => handleChange("MC_Number", e.target.value, true)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
            <TextField
              error={submitted && !company_information.USDOT_Number.isCorrect}
              helperText={submitted ? company_information.USDOT_Number.helperText : ''}
              required
              fullWidth
              id="USDOT_Number"
              className=""
              label="USDOT Number"
              placeholder=""
              value={company_information['USDOT_Number']?.value || ''}
              onChange={(e) => handleChange("USDOT_Number", e.target.value, true)}
              InputProps={{
                startAdornment: <InputAdornment position="start"></InputAdornment>,
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-item">
        <BtnRegistration
          onClick={handleSubmit}
          classes={'w-100'}
        />
      </div>
    </div>
  );
};
