import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";

import { Dispatch, STORE } from "./_redux/types";
import { mainService } from "./_redux/_requests";
import { allActions, userActions } from "./_redux/_actions";
import { authenticationContants, allConstants } from "./_redux/_constants";
import { Container } from "./Container";
import { Registration } from "./screens/Registration";
import { Login_ForgotPass } from "./screens/Login_ForgotPass";

function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation(); // Добавлено для получения текущего пути
  const dispatch: Dispatch = useDispatch();
  const { reg_id } = useSelector((state: any) => state.appData);

  useEffect(() => {
    (async () => {
      const data = sessionStorage.getItem("userData");
      if (
        location.pathname.includes("registration-company") ||
        location.pathname.includes("login")
      )
        return;
      if (data && !reg_id) {
        const userDataResult = JSON.parse(data || "{}");
        const userData = {
          token: userDataResult.token,
          reg_id: userDataResult.reg_id,
        };
        mainService.setToken(userData.token);
        const get_my_driver_data_res: any = await dispatch(
          allActions.loadAndSetInRedux(
            allConstants.LOAD_USER_DATA_SUCCESS,
            {
              action: "get_my_company_data",
            },
            allConstants.LOAD_USER_DATA_FAILURE
          )
        );
        if (
          get_my_driver_data_res.type === allConstants.LOAD_USER_DATA_FAILURE ||
          get_my_driver_data_res.type === allConstants.ACTION_FAILURE
        ) {
          dispatch(userActions.logout());
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    })();
  }, []);

  return (
    <Routes>
      <Route path="/registration-company" element={<Registration />} />
      <Route
        path="/registration-company-finish/*"
        element={<Registration page={3} />}
      />
      <Route path="/login" element={<Login_ForgotPass />} />
      <Route path="/*" element={reg_id ? <Container /> : null} />
      {/* <Route path="/*" element={true ? <Container /> : null} /> */}
    </Routes>
  );
}

export { AppRoutes };
