import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { HeaderApplicantsList } from "../../components/HeaderApplicantsList";
import { ApplicantsListTable } from "../../components/ApplicantsListTable";
import { SegmentedButton } from "../../components/SegmentedButton";
import { TableReject } from "../../components/TableReject/TableReject";
import { Dispatch, STORE } from "../../_redux/types";
import { allActions } from "../../_redux/_actions";
import { allConstants } from "../../_redux/_constants/index";

import "./style.less";

// filters
const ApplicantsTypes = [
  {
    id: "active",
    text: "Active",
    isActive: true,
  },
  {
    id: "draft",
    text: "Draft",
    isActive: false,
  },
  {
    id: "rejected",
    text: "Rejected",
    isActive: false,
  },
];

export const ApplicantsListScreen = () => {
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(ApplicantsTypes);
  const applications = useSelector((state: STORE) => state.appData.applications);
  const { company_id } = useSelector((state: STORE) => state.appData.company_information);
  const { application_statuses } = useSelector((state: STORE) => state.appData.dictionaries);

  const [filteredApplications, setFilteredApplications] =
    useState(applications);

  useEffect(() => {
    (async () => {
      const res: any = await applications_get_by_company();
    })();
  }, [company_id]);

  useEffect(() => {
    setFilteredApplications(applications);
  }, [applications]);

  const activeList = useMemo(() => {
    return filteredApplications.filter((appl) => {
      const stdata = application_statuses.find(
        (st) => st.dict_id == appl?.applic_status
      );
      return (
        stdata?.code === "application_status_new" ||
        stdata?.code === "application_status_pending" ||
        stdata?.code === "application_status_hiring" ||
        stdata?.code === "application_status_training"
      );
    });
  }, [filteredApplications, application_statuses]);

  const draftList = useMemo(() => {
    return filteredApplications.filter((appl) => !appl?.applic_status);
  }, [filteredApplications]);

  const rejectedList = useMemo(() => {
    return filteredApplications.filter((appl) => {
      const stdata = application_statuses.find(
        (st) => st.dict_id == appl?.applic_status
      );
      return stdata?.code === "application_status_reject";
    });
  }, [filteredApplications, application_statuses]);

  const activeTab = useMemo(() => {
    let activeTab: any = tabs.find((tab) => tab.isActive);
    return activeTab?.id;
  }, [tabs]);

  const applications_get_by_company = async () => {
    return await dispatch(
      allActions.loadAndSetInRedux(allConstants.LOAD_APLICATIONS_SUCCESS, {
        action: "applications_get_by_company",
        company_id: company_id,
      })
    );
  };

  function selectTab(id) {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        isActive: id === tab.id && !tab.isActive,
      }))
    );
  }

  return (
    <div className="p-3">
      <div className="body screen-border">
        <HeaderApplicantsList
          pageName={"Applicants"}
          segmentedButton={
            <SegmentedButton btns={tabs} selectTab={selectTab} />
          }
          actionsBlock={null}
          onSearch={setFilteredApplications}
          data={applications}
          keys={[
            "driver_first_name",
            "driver_last_name",
            "email",
            "phone_number",
          ]}
        />
        {activeTab === "rejected" ? (
          <TableReject drivers={rejectedList} />
        ) : (
          <ApplicantsListTable
            drivers={activeTab === "active" ? activeList : draftList}
          />
        )}
      </div>
    </div>
  );
};
