
export function tr(key) {
    return translater[key] ? translater[key] : key
}

const translater = {
	WRONG_FORMAT: 'Wrong format',
	OUTDATED_TOKEN: 'Time is up. Try again.',
	EMAIL_DUPLICATE: 'The email is duplicated, operation is not possible.',
	WRONG_PWD: 'The password is not correct',
	USER_NOT_FOUND: 'User not found',
	FIELD_MUST_BE_UNIQUE: 'Field must be unique',
};
