import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { IconArrowDropDown } from "../../icons/IconArrowDropDown";
import { userActions } from "../../_redux/_actions";

import "./style.css";

import { Drawer } from "@mui/material";
import { DrawerUser } from "../DrawerUser/DrawerUser";
import { DrawerChangePassword } from "../DrawerChangePassword/DrawerChangePassword";

export const DropDownMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);

  const dropDownRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    // @ts-ignore
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleLogout = () => {
    setIsOpen(false); // закрываем меню
    dispatch(userActions.logout());
    navigate("/login");
  };

  const openDrawer = (content) => () => {
    setDrawerContent(content);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerContent(null);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="drop-down-container" ref={dropDownRef}>
      <div onClick={toggleMenu}>
        <IconArrowDropDown />
      </div>
      {isOpen && (
        <div className="drop-down-menu">
          <ul>
            <li onClick={openDrawer(<DrawerUser onClose={closeDrawer} />)}>
              My profile
            </li>
            {/* потім будемо дороблювати функціонал */}
            {/* <li
              onClick={openDrawer(
                <DrawerChangePassword onClose={closeDrawer} />
              )}
            >
              Change password
            </li> */}
            <li onClick={handleLogout}>Logout</li>
          </ul>
        </div>
      )}
      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        {drawerContent}
      </Drawer>
    </div>
  );
};
