import React, { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';

import { Checkboxes } from "../../../components/Checkboxes";
import { IconVisibility15 } from "../../../icons/IconVisibility15";
import { IconVisibility19 } from "../../../icons/IconVisibility19";
import CheckboxesMUI from "../../../components/CheckboxesMUI";
import { BtnRegistration } from '../../../components/BtnRegistration';
import { BtnText } from "../../../components/BtnText";
import { Dispatch, STORE, IObj } from '../../../_redux/types';
import { userActions } from '../../../_redux/_actions';
import { PASSWORD_LENGTH_MIN, allConstants } from '../../../_redux/_constants';
import { validations, validate } from '../../../_redux/validations';

import "./style.less";

type Props = {
  go_to_forgot: () => void
}

export const Login = (props: Props) => {
  const { go_to_forgot } = props;
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();

  const {email, pass} = useSelector((state: STORE) => state.appData.authData);

  const [typePassword, setTypePassword] = useState("password");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");

	const set_key = (key2: string, value: string | null | IObj) => {
    dispatch({
      type: allConstants.SET_KEY,
      payload: {
        key1: 'authData',
        key2,
        value
      }
    });
	};

	const changeEmail = async (value: string) => {
    let errtext = validate(value, validations["company_information.Work_E_mail"]);
    setEmailErr(errtext);
    set_key('email', {
      value,
      isCorrect: !errtext,
      helperText: errtext,
    })
    set_key('pass', {
      value: pass.value,
      isCorrect: true,
      helperText: '',
    })
	};

	const changePass = (value: string) => {
    let errtext = ""
    if(value.length < PASSWORD_LENGTH_MIN) {
      errtext = "Short password";
    }
    setPassErr(errtext);
    set_key('pass', {
      value,
      isCorrect: !errtext,
      helperText: errtext,
    })
	};

	const login = async (event?: ChangeEvent<HTMLInputElement>) => {
    if(!emailErr && !passErr) {
      const res: any = await dispatch(userActions.login(email.value, pass.value)) as any;
      if(res?.status === 'ok') {
        navigate("/");
      }
    }
	};

  return (
    <div className="login-get-stated">
      <div className="form-header">
        <div className="title">Get Started</div>
        <p className="subtitle">Enter your credentials to access your account</p>
      </div>
      <div className="column-gap-32">
        <div className="">
          <TextField
            required
            fullWidth
            id="E-mail"
            className=""
            label="E-mail"
            placeholder="Your e-mail"
            InputProps={{
              startAdornment: <InputAdornment position="start"></InputAdornment>,
            }}
            value={email.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              changeEmail(event.target.value);
            }}
            helperText={email.helperText}
            error={!email.isCorrect}
          />
        </div>
        <div className=""> 
          <TextField
            required
            fullWidth
            id="Password"
            className=""
            label="Password"
            placeholder=""
            type ={typePassword}
            InputProps={{
              endAdornment: <InputAdornment
                position="end"
                onClick={() => {
                  setTypePassword(typePassword === "password" ? "text" : "password");
                }}
                sx={{
                  "&:hover": {
                    cursor: "pointer"
                  }
                }}
              >
                {typePassword === "password" ? <IconVisibility15 className="icon-visibility"/>
                  : <IconVisibility19 className="icon-visibility"/>}
              </InputAdornment>,
            }}
            value={pass.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              changePass(event.target.value);
            }}
            helperText={pass.helperText}
            error={!pass.isCorrect}
           />
        </div>
      </div>
      <div className="remember-forgot">
        {/* <CheckboxesMUI/> */}
        <BtnText
          submitText={"Go to registration"}
          handleClick={ () => {navigate("/registration-company");}}
        />
        <BtnText
          submitText={"Forgot password?"}
          handleClick={ () => {go_to_forgot()}}
        />
      </div>
      {/* <div className="remember-forgot">  */}
      <div className="right-content w-100">
        {/* <BtnText
          submitText={"Go to driver cabinet"}
          handleClick={ () => {window.location.href = "/dwf";}}
        /> */}
        <BtnRegistration
          submitText={'Login'}
          onClick={() => {login()}}
          classes={''}
        />
      </div>
    </div>
  );
};
