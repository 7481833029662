import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

export const searchInArray = (array, keys, searchValue) => {
  if (!searchValue) {
    return array;
  }
  let filteredArray = [];
  if (array && array.length) {
    filteredArray = array.filter((item) =>
      keys.some((key) => {
        const value = key.split(".").reduce((acc, k) => acc[k], item);
        return value
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
    );
  }

  return filteredArray;
};

export const Search = ({ data, keys, placeholder, onFiltered }) => {
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const filteredData = searchInArray(data, keys, searchValue);
    onFiltered && onFiltered(filteredData);
  }, [searchValue, data, keys, onFiltered]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      value={searchValue}
      onChange={handleSearchChange}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        width: "210px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "&:hover fieldset": {
            borderColor: "#000",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#3f51b5",
          },
        },
      }}
    />
  );
};
