
import React from "react";

export const IconBurger = ({ className }) => {
  return (
    <svg
      className={`icon-burger ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M21 6L3 6L3 8L21 8L21 6ZM21 11L3 11L3 13L21 13L21 11ZM21 18L21 16L3 16L3 18L21 18Z"
        fill="#6B6D82"
      />
    </svg>
  );
};
