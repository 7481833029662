
import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from "@mui/base";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckIcon from "@mui/icons-material/Check";

import {
  AddTagButton,
  DeleteTagButton,
  MyIconButton,
} from "../../screens/VendorsScreen/ButtonsVendors";
// @ts-ignore
import sprite from "../../icons/sprite.svg";
import { RejectModal } from "../RejectModal/RejectModal";
import { PendingModal } from "../PendingModal";
import { Dispatch, STORE, Application, Dictionary } from "../../_redux/types";
import { dateEdit} from "../../js/helpers";
import { StatusDriverApplicants } from "../StatusDriverApplicants";
import { allActions } from '../../_redux/_actions';
import { allConstants } from "../../_redux/_constants";

import "./style.less";

export const HeaderApplicants = ({ name, info }) => {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  let { applicantId } = useParams();
  const {applications} = useSelector((state: STORE) => state.appData);
  const {application_statuses} = useSelector((state: STORE) => state.appData.dictionaries);

  const [isModalOpenReject, setIsModalOpenReject] = useState(false);
  const [isModalPending, setIsModalPending] = useState(false);

  const application: Application = useMemo(() => {
    const application = applicantId ? applications.find( (appl) => appl.id == Number(applicantId?.slice(1))) : {};
    return application as Application;
  }, [applicantId, applications]);

  const applicationDate = useMemo(() => {
    const createTime = Number(new Date(application?.create_time));
    const timeString = dateEdit(createTime, false) as string;
    return timeString;
  }, [application])

  const statusActions = useMemo(() => {
    const statusData_ = application?.applic_status ? (application_statuses.find( (st) => st.dict_id == application?.applic_status ) || {}) as Dictionary : {} as Dictionary;
    const New = <Button
      className="header-button-applicants"
      onClick={() => {
        setNewStatus('application_status_new');
      }}
    >
      <CheckIcon fontSize="small" />
      New
    </Button>
    const Pending = <Button
      className="header-button-applicants"
      onClick={() => {
        setIsModalPending(true);
      }}
    >
      <CheckIcon fontSize="small" />
      Pending
    </Button>
    const Training = <Button
      className="header-button-applicants"
      onClick={() => {
        setNewStatus('application_status_training');
      }}
    >
      <CheckIcon fontSize="small" />
      Training
    </Button>
    const Hiring = <Button
      className="header-button-applicants"
      onClick={() => {
        setNewStatus('application_status_hiring');
      }}
    >
      <CheckIcon fontSize="small" />
      Hiring
    </Button>
    const Reject = <Button
      className="header-button-applicants"
      onClick={() => {
        setIsModalOpenReject(true);
      }}
    >
      <svg width="18" height="18">
        <use href={sprite + "#icon-cancel"}></use>
      </svg>
      Reject
    </Button>
    if(statusData_.code === 'application_status_new') {
      return <>
        {Pending}
        {Reject}
      </>
    }
    if(statusData_.code === 'application_status_pending') {
      return <>
        {Hiring}
        {Training}
        {Reject}
      </>
    }
    if(statusData_.code === 'application_status_training') {
      return <>
        {/* {Pending} */}
        {Hiring}
        {Reject}
      </>
    }
    if(statusData_.code === 'application_status_hiring') {
      return <>
        {/* {Pending} */}
        {Reject}
      </>
    }
    if(statusData_.code === 'application_status_reject') {
      return <>
        {Pending}
      </>
    }
    return New;
  }, [application?.applic_status, application_statuses]);

  async function setNewStatus(status: string, desc?: string) {
    const res: any = await allActions.applicationSetStatusNew(dispatch, application.id, status, desc);
  };

  const modaRejectChange = () => setIsModalOpenReject(!isModalOpenReject);
  const modaPendingChange = () => setIsModalPending(!isModalPending);

  return (
    <>
      <div className="head">
        <div className="header-title">
          <div className="header-div">
            <div className="screen-header-title-container ">
              <MyIconButton onClick={() => navigate(-1)} />
              <div className="screen-header-title ">
                <span className="header-vendors"> {name} /</span> {info}
              </div>
              <StatusDriverApplicants
                className="driver-applicants-status"
                status={application?.applic_status}
              />
            </div>
            <div className="header-buttons">
              {statusActions}
              {/* <Button className="header-button-applicants">
                <MoreHorizIcon />
              </Button> */}
            </div>
          </div>
        </div>
        <div className="header-tags">
          {/* теги поки не робимо */}
          {/* <DeleteTagButton />
          <AddTagButton /> */}
          <p className="text-gray-12">
            Application Date: <span>{applicationDate}</span>
          </p>
        </div>
      </div>

      {isModalPending && (
        <PendingModal open={isModalPending} onClose={modaPendingChange} confirm={() => {
          setNewStatus('application_status_pending');
          modaPendingChange();
        }}/>
      )}
      {isModalOpenReject && (
        <RejectModal
          open={isModalOpenReject}
          onClose={modaRejectChange}
          confirm={(desc) => {
            setNewStatus('application_status_reject', desc);
            modaRejectChange();
          }}
        ></RejectModal>
      )}
    </>
  );
};
