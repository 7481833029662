
export const validations = {}
validations["company_information.First_Name"] = [noEmptyValidate, validateLength(2)];
validations["company_information.Last_Name"] = [noEmptyValidate, validateLength(2)];
validations["company_information.Phone_Number"] = [noEmptyValidate, numberValidate, validateLength(7, 13)];
validations["company_information.Work_E_mail"] = [noEmptyValidate, emailValidate];
validations["company_information.Legal_Name"] = [noEmptyValidate, validateLength(3)];
validations["company_information.Phisical_Adress"] = [noEmptyValidate, validateLength(3)];
validations["company_information.MC_Number"] = [noEmptyValidate, numberValidate, validateLength(5, 8)];
validations["company_information.USDOT_Number"] = [noEmptyValidate, numberValidate, validateLength(5)];

export function validate(value, validations) {
    let errorText = ''
    if (validations && Array.isArray(validations)) {
        validations.forEach(f => {
            const message = f(value);
            if (message.length && !errorText) {
                errorText = message;
            }
        })
    }
    return errorText;
}

export function noEmptyValidate(value: any) {
    return (/.+/.test(value)) ? '' : 'The field cannot be empty';
}

export function minMaxValue (min:number, max:number, textErrorMin:string, textErrorMax:string) {
    return (value:number) => {
        if (max && value > max) {
            return textErrorMax ? textErrorMax : `The value must not exceed ${max}`;
        } else if (value < min) {
            return textErrorMin ? textErrorMin : `The value must not be less than ${min}`;
        }
        return '';
    }
}

export function validateLength (min:number, max?:number, textError?:string) {
    return (value:string) => {
        if (typeof value !== 'string') {
            throw Error('Type Error in validateLength')
        } else if (value) {
            if (value.toString().length < min)
                return textError ? textError : `The value must be ${min} characters long`;
            if (max && value.toString().length > max)
                return textError ? textError : `The value must be ${max} characters long`;
        }
        return '';
    }
}

export function numberValidate (number:string) {
    return !(/\D+/.test(number)) || !number ? '' : 'The value must be only digits';
}

export function emailValidate (email:string) {
    return /.{2,}@.{2,}\..{2,}/.test(email) || !email ? '' : 'The email invalid';
}
