
import * as React from 'react';
import { IconVisibility27 } from "../IconVisibility27";

import "./style.less";

export const IconButtonSmall = ({
  showTooltip = true,
  tooltipText = "Tooltip Text",
  configuration,
  stateProp,
  icon = <IconVisibility27 className="icon-visibility" color="#3749E5" />,
  onClick = () => {},
  tooltipClassName,
  classes = "",
}) => {
  const state = {
    configuration: configuration || "outlined",
    state: stateProp || "disabled",
  };

  return (
    <div
      className={`icon-button-small`}
      onClick={onClick}
    >
      <div className="container">
        <div className="icon-visibility-wrapper">
          {icon}

          {/* око але синє */}
          {/* <IconVisibility27
            className="icon-visibility"
            color={
              state.configuration === "outlined" && ["focused", "pressed"].includes(state.state)
                ? "#6B6D82"
                : state.configuration === "filled"
                ? "white"
                : state.state === "disabled"
                ? "#171F30"
                : "#3749E5"
            }
          /> */}
        </div>
      </div>
      {/* {showTooltip && (
        <div className={`tooltip ${tooltipClassName}`}>
          <div className="tooltip-text">{tooltipText}</div>
          {state.state === "hovered" && <img className="vector" alt="Vector" src="/img/vector-1-4.svg" />}
        </div>
      )} */}
    </div>
  );
};
