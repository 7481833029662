import React, { useMemo } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { HeaderCompanyWorker } from "../../components/HeaderCompanyWorker";
import { CompanyWorkerData } from "../../components/CompanyWorkerData";
import { allActions } from '../../_redux/_actions';
import { paths } from "../../_redux/_constants";
import { Dispatch, STORE, CompanyWorker } from "../../_redux/types";

import "./style.less";

export const CompanyWorkerScreen = () => {
  const navigate = useNavigate();
  let { companyWorkerId } = useParams();
  
  const dispatch: Dispatch = useDispatch();
  const {companyWorkers} = useSelector((state: STORE) => state.appData);
  const {company_id} = useSelector((state: STORE) => state.appData.company_information);

  const worker: CompanyWorker | {} = useMemo(() => {
    if(companyWorkers.length) {
      // @ts-ignore
      const worker = {...companyWorkers.find( (worker) => worker.company_worker_id == companyWorkerId?.slice(1))};
      if(!worker) navigate(`/${paths.COMPANY_WORKERS_LIST}`)
      return worker || {};
    } else {
      const res: any = allActions.getCompanyWorkers(dispatch, company_id);
    }
    return {};
  }, [companyWorkers, companyWorkerId]);

  return (
    <div className="p-3 screen-body">
      <div className="h-100">
        <HeaderCompanyWorker
          worker={worker as CompanyWorker}
        />
        <CompanyWorkerData worker={worker as CompanyWorker}/>
      </div>
    </div>
  );
};
