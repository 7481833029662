import React from 'react';
import Button from '@mui/material/Button';

// import "./style.less";

type Props = {
    onClick: () => void,
    icon?: any,
    submitText?: string,
    classes?: string,
}
const BtnRegistration = ({ onClick, submitText = 'Continue', icon = null, classes }: Props) => {
  return (
    <Button
      variant="contained"
      className={`btn-primary padding-18-32 ${classes}`}
      style={{ textTransform: 'none' }}
      onClick={onClick}
    >
      {icon}
      {submitText}
    </Button>
  );
};

export { BtnRegistration };