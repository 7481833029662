import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { IconButton } from "@mui/material";

import { dateEdit } from "../../js/helpers";
import { allActions } from '../../_redux/_actions';

import "./style.less";

// @ts-ignore
import sprite from "../../icons/sprite.svg";

// чек лист с зеленой галочкой
export const ChekItem = ({title = "_____", subTitle = "_____", data = {}, key_id,key_time,}: any) => {
  const dispatch = useDispatch();
  const upload_time = useMemo(() => {
    if (!data[key_time]) return "";
    const currentDateTime = Number(new Date(data[key_time]));
    const timeString = dateEdit(currentDateTime, false); 
    return timeString;
  }, [data]);

  async function loadImage() {
    const userData = sessionStorage.getItem('userData');
    const userDataResult = JSON.parse(userData || '{}');
    const imgUrl = `images/${data[key_id]}/${userDataResult.token}`;
    const res: any = await allActions.requestGet(imgUrl, {
      responseType: 'arraybuffer',
    });
    if (res) {
      let b64Response = btoa(
        new Uint8Array(res?.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '',
        ),
      );

      const link = document.createElement('a');
      link.href = `data:${res.headers['content-type']};base64,` + b64Response;
      link.download = subTitle;
      link.click();
    }
  }

  async function showImg() {
    const userData = sessionStorage.getItem('userData');
    const userDataResult = JSON.parse(userData || '{}');
    const imgUrl = `images/${data[key_id]}/${userDataResult.token}`;
    const res: any = await allActions.requestGet(imgUrl, {
      responseType: 'arraybuffer',
    });
    if (res) {
      let b64Response = btoa(
        new Uint8Array(res?.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          '',
        ),
      );
      var image = new Image();
      image.src =
        `data:${res.headers['content-type']};base64,` + b64Response;

      let w = window.open('', '_blank');
      w?.document.write(image.outerHTML);
      w?.document.close();
    }
  }

  return (
    <div className="CheklistItemOk col-md-6 mb-3">
      <div className="chek-container">
        <div>
          <svg width="40" height="40">
            {data[key_time] ? (
              <use href={sprite + "#icon-Ok"}></use>
            ) : (
              <use href={sprite + "#icon-No"}></use>
            )}
          </svg>
        </div>
        <div className="text-container">
          <div className="text-main-13 font-w-600">{title}</div>
          <div
            className="chek-subtitle"
            onClick={showImg}
          >
            {subTitle}
          </div>
        </div>
        {upload_time ? <div className="chek-date">{upload_time}</div> : null}
        {data[key_time] ? (
          <>
            <IconButton
              className="chek-icon-button"
              style={{
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
              onClick={async () => {
                loadImage();
              }}
            >
              <svg width="24" height="24">
                <use href={sprite + '#icon-download'}></use>
              </svg>
            </IconButton>
            <IconButton
              className="chek-icon-button"
              style={{
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
              onClick={showImg}
            >
              <svg width="24" height="24">
                <use href={sprite + '#icon-navigate_next'}></use>
              </svg>
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              className="chek-icon-button"
              style={{
                width: '40px',
                height: '40px',
                cursor: 'not-allowed',
                opacity: 0.5,
              }}
            >
              <svg width="24" height="24">
                <use href={sprite + '#icon-download'}></use>
              </svg>
            </IconButton>
            <IconButton
              className="chek-icon-button"
              style={{
                width: '40px',
                height: '40px',
                cursor: 'not-allowed',
                opacity: 0.5,
              }}
              disabled={true}
            >
              <svg width="24" height="24">
                <use href={sprite + '#icon-navigate_next'}></use>
              </svg>
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};
// чек лист required
export const ChekItemRequired = ({ content = 'Note text goes here.' }) => {
  return (
    <div className="CheklistItemOk col-md-6 mb-3">
      <div className="chek-container">
        <div className="icon-container">
          <svg width="40" height="40">
            <use href={sprite + "#icon-No"}></use>
          </svg>
          <div className="error-icon">
            <svg width="18" height="18">
              <use href={sprite + "#icon-error"}></use>
            </svg>
          </div>
        </div>
        <div className="text-container">
          <div className="text-main-13 font-w-600">Proper “DOT” Application</div>
          <div className="chek-subtitle">FMCSA 49 CFR Part 391.21</div>
        </div>
        <IconButton
          className="chek-icon-button"
          style={{
            width: "40px",
            height: "40px",
            cursor: "not-allowed",
            opacity: 0.5,
          }}
          disabled={true} // Отключаем кнопку
        >
          <svg width="24" height="24">
            <use href={sprite + "#icon-navigate_next"}></use>
          </svg>
        </IconButton>
      </div>
    </div>
  );
};
